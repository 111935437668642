import { useState, useCallback, useEffect } from "react"
//import { NextPageContext } from "next"
//import { parseCookies, setCookie } from "nookies"
import cookie from "react-cookies"
import { createSingletonHook } from "./create-singleton-hook"

export type Theme = "light" | "dark"

export const [useTheme, ThemeProvider] = createSingletonHook(
    (initialTheme: Theme) => {
        const [theme, setTheme] = useState(initialTheme)

        const toggleTheme = useCallback(() => {
            setTheme(theme => (theme === "light" ? "dark" : "light"))
        }, [])

        useEffect(() => {
            saveTheme(theme)
        }, [theme])

        return {
            theme,
            toggleTheme,
        }
    }
)

export type ThemePageContext = { theme: Theme }

let cachedTheme: Theme = "light"

const saveTheme = (theme: Theme) => {
    cachedTheme = theme
    cookie.save("theme", theme, {
        maxAge: 30 * 24 * 60 * 60,
        path: "/",
    })
}

export const getTheme = () => {
    const { theme } = cookie.loadAll()

    if (theme === "light" || theme === "dark") {
        cachedTheme = theme
    } else {
        saveTheme(cachedTheme)
    }

    return cachedTheme
}
