import { signHex, getPublicKey, SeedPhrase } from "../services/crypto"
import { navigate } from "gatsby"
import { User, SignedTransaction, Transaction } from "../types"
import { showToast } from "../components/status-notification"
import { faInfoCircle } from "@fortawesome/pro-regular-svg-icons"

//const url = "http://localhost:6754"
const url = process.env.NETWORK_URL

export const validateMnemonic = (mnemonic: string) => {
  return SeedPhrase.validate(mnemonic)
}

export const generateMnemonic = async () => {
  const seedPhrase = SeedPhrase.new_random().phrase
  return seedPhrase
}

export const getProfile = async (
  key: string,
  callBack: (res: any, pk: string) => void
) => {
  const [pk, pkHex] = getPublicKey(key)
  rpc_impl("get_account_data", pkHex, "", key, function (res) {
    callBack(res, pk)
  })
}
/*
export const like = async (
  key: string,
  callBack: (arg0: { status: string; data: any }) => void
) => {
  await load()
  const kp = EdKeyPair.from_phrase(key)
  const pk = kp.pubKey()
  const pkHex = utils.u8a2Hex(pk)
  rpc("get_account_data", pkHex, null, key, function (res) {
    callBack(res)
  })
}
*/
//console.log("pendingCall = null")
export let pendingCall = null

export const resetPendingCall = () => {
  pendingCall = null
}

export const rpc = async (
  method: string,
  data: string,
  user: User | null,
  key: string | null,
  callBack: (arg0: { status: string; data: any }) => void
) => {
  /*if (user?.account_type == "local") {
    console.log("local account rpc")
    return
  }*/

  //console.log("*** rpc: " + key)
  if (key == null || key == "") {
    pendingCall = { method: method, data: data, callBack: callBack }
    //console.log("*** rpc: not logged in" + pendingCall)
    navigate("/login", { state: { modal: true } })
    return
  }

  //await load()

  if (method == "test_donate") {
    let msg = JSON.parse(data)
    const [pk, pkHex] = getPublicKey(key)
    msg.pk = pkHex
    rpc_impl(method, JSON.stringify(msg), null, key, function (res) {
      callBack(res)
    })

    return
  } else if (method == "get_messages" || method == "get_dm_public_key") {
    rpc_impl(method, data, user, key, function (res) {
      callBack(res)
    })

    return
  }

  if (user && user.id == "") {
    getProfile(key, function (res, pk) {
      if (res.status == "ok") {
        user.id = res.data.id
        rpc_impl("get_balance", user.id, user, key, function (res) {
          if (res.status == "ok") {
            let ts = Math.floor(Date.now() / 1000)
            let tx = {
              kind: method,
              /*anchor: res.data.anchor,*/
              ts: ts,
              data: data,
            }
            rpc_impl(method, JSON.stringify(tx), user, key, callBack)
          }
        })
      } else {
        showToast(
          faInfoCircle,
          "Smartlike network",
          "No funds on the account. You may consider donating to your favorite authors to fill the account"
        )
      }
    })
  } else {
    /*
    rpc_impl("get_balance", user.id, user, key, function (res) {
      if (res.status == "ok") {
        let ts = Math.floor(Date.now() / 1000)
        let tx = {
          kind: method,
          anchor: res.data.anchor,
          ts: ts,
          data: data,
        }
        rpc_impl(method, JSON.stringify(tx), user, key, callBack)
      }
    })
    */
    let ts = Math.floor(Date.now() / 1000)
    let tx = {
      kind: method,
      /*anchor: "", //res.data.anchor,*/
      ts: ts,
      data: data,
    }
    rpc_impl(method, JSON.stringify(tx), user, key, callBack)
  }
}

export const rpc_impl = async (
  method: string,
  data: string,
  user: User | null,
  key: string | null,
  callBack: (arg0: { status: string; data: any }) => void
) => {
  //await load()
  //const kp = EdKeyPair.from_phrase(key)
  //const pk = kp.pubKey()
  //const pkHex = utils.u8a2Hex(pk)

  //console.log("rpcCall " + data)
  const sig = signHex(data, key) //await sign_string(data, key)
  const message = {
    jsonrpc: "2.0",
    method: method,
    id: 1234,
    params: {
      signed_message: {
        sender: user ? user.id : "",
        signature: sig,
        data: data,
      },
    },
  }

  fetch(url, {
    method: "POST",
    headers: {
      "Content-type": "application/json",
    },
    body: JSON.stringify(message),
  })
    .then(res => {
      status = res.status
      return res.json()
    })
    .then(jsonData => {
      //console.log(jsonData)
      //console.log(status)

      if (callBack) {
        if (status == 200) {
          callBack(jsonData)
        } else {
          callBack({
            status: "failed",
            data: status,
          })
        }
      }
    })
    .catch(err => {
      if (callBack) {
        callBack({
          status: "failed",
          data: err,
        })
      }
    })
}
/*
const sign_string = async (str: string, kp: EdKeyPair) => {
  const fieldsToSignU8a = utils.toUtf8(str)
  const sigBytes = kp.sign(fieldsToSignU8a)
  return utils.u8a2Hex(sigBytes)
}*/
