import { useState } from "react"
import { createSingletonHook } from "./create-singleton-hook"
import { useMediaQuery } from "react-responsive"

export const [useMedia, MediaProvider] = createSingletonHook(() => {
  const isDesktopLayout = useMediaQuery({
    query: "(min-width: 1280px)",
  })

  const isMobileLayout = useMediaQuery({ query: "(max-width: 1224px)" })
  const isLandscape = useMediaQuery({
    query: "screen and (min-aspect-ratio: 13/9)",
  })

  const isTouchDevice =
    typeof window !== "undefined"
      ? "ontouchstart" in window || navigator.maxTouchPoints > 0
      : false

  const numColumns = isTouchDevice ? (isLandscape ? 2 : 1) : 3

  /*
  const isTouchDevice = true
  const numColumns = 2
  */
  /*
  const numColumns = useMediaQuery({
    query: "(min-width: 1280px)",
  })
    ? 3
    : useMediaQuery({
        query: "(min-width: 600px)",
      })
    ? 1
    : 1*/

  const isBigScreen = useMediaQuery({ query: "(min-width: 1280px)" })
  const isTallScreen = useMediaQuery({ query: "(min-height: 600px)" })
  const isAndroid =
    typeof navigator != "undefined"
      ? /android/i.test(navigator.userAgent)
      : false
  const isChrome =
    typeof navigator != "undefined"
      ? /Chrome/.test(navigator.userAgent) &&
        /Google Inc/.test(navigator.vendor)
      : false

  return {
    isDesktopLayout,
    isMobileLayout,
    isTouchDevice,
    numColumns,
    isBigScreen,
    isTallScreen,
    isAndroid,
    isChrome,
  }
})
