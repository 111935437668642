import { ComponentType, createElement, createContext, useContext } from "react"

export const createSingletonHook = <P, S>(
  useHook: (props: P) => S
): [() => S, ComponentType<{ value: P }>] => {
  const Context = createContext<S | undefined>(undefined)

  const SingletonHookProvider: ComponentType<{ value: P }> = props => {
    const value = useHook(props.value)
    return createElement(Context.Provider, { value }, props.children)
  }

  const useSingletonHook = (): S => {
    const value = useContext(Context)
    if (typeof value === "undefined") {
      throw new Error(
        "Component must be a wrapped in a singleton hook Provider"
      )
    }
    return value
  }

  return [useSingletonHook, SingletonHookProvider]
}
