import { useState, useCallback, useEffect } from "react"
import { createSingletonHook } from "./create-singleton-hook"
import { Item, Channel, Comment } from "../types"
import { Filters } from "./use-filters"

const createCommonIndices = (store: IDBObjectStore) => {
  store.createIndex("alias", "alias", { unique: false })
  store.createIndex("domain", "domain", { unique: false })
  store.createIndex("item", "item", { unique: false })
  store.createIndex("amount", "amount", { unique: false })
  store.createIndex("tags", "tags", {
    unique: false,
    multiEntry: true,
  })
  store.createIndex("ts", "ts", { unique: false })
  store.createIndex("amount-ts", ["amount", "ts"], {
    unique: false,
  })
  store.createIndex("alias-ts", ["alias", "ts"], { unique: false })
  store.createIndex("alias-amount-ts", ["alias", "amount", "ts"], {
    unique: false,
  })
  store.createIndex("domain-ts", ["domain", "ts"], {
    unique: false,
  })
  store.createIndex("domain-amount-ts", ["domain", "amount", "ts"], {
    unique: false,
  })
}

export const [useIndexedDB, IndexedDBProvider] = createSingletonHook(() => {
  const dbPromise = new Promise(function (resolve) {
    if (typeof window === "undefined") {
      // for gatsby server side build
      //console.timeEnd("openDB")
      resolve(null)
    }

    console.log("opening indexeddb")
    console.time("openDB")
    let openRequest = indexedDB.open("Smartlike", 5)

    openRequest.onupgradeneeded = function (event) {
      console.log("indexeddb onupgradeneeded, version " + event.oldVersion)
      try {
        let store
        if (event.oldVersion < 1) {
          store = openRequest.result.createObjectStore("muted")

          store.createIndex("meta", "meta", { unique: false })
          store.createIndex("ts", "ts", { unique: false })

          store = openRequest.result.createObjectStore("item_likes")
          createCommonIndices(store)
          console.log("created db " + openRequest.result)

          store = openRequest.result.createObjectStore("author_likes")
          createCommonIndices(store)
          console.log("created db " + openRequest.result)

          store = openRequest.result.createObjectStore("website_likes")
          createCommonIndices(store)
          console.log("created db " + openRequest.result)
        }

        if (event.oldVersion < 5) {
          store = openRequest.result.createObjectStore("messages")
          console.log("created db " + openRequest.result)
        }

        //resolve(openRequest.result)
      } catch (e) {
        console.log("caught error " + e)
      }
    }

    openRequest.onerror = function () {
      console.error("Error", openRequest.error)
    }

    openRequest.onsuccess = function () {
      console.timeEnd("openDB")
      console.log("openDB " + new Date().getTime())
      //setDb(openRequest.result)
      resolve(openRequest.result)
    }
  })

  const addKV = async (storeName: string, key: string, value: any) => {
    console.log("adding key")
    let database = await dbPromise

    if (database) {
      let transaction = database.transaction(storeName, "readwrite") // (1)
      let store = transaction.objectStore(storeName) // (2)
      return store.add(value, key) // (3)
      /*
          request.onsuccess = function() { // (4)
              console.log("Book added to the store", request.result);
          };
          
          request.onerror = function() {
              console.log("Error", request.error);
          };*/
    } else {
      console.log("no db")
      return null
    }
  }

  const putKV = async (storeName: string, key: string, value: any) => {
    console.log("putting key")
    let database = await dbPromise

    if (database) {
      let transaction = database.transaction(storeName, "readwrite") // (1)
      let store = transaction.objectStore(storeName) // (2)
      return store.put(value, key) // (3)
    } else {
      console.log("no db")
      return null
    }
  }

  const deleteKV = async (storeName: string, key: string) => {
    let database = await dbPromise

    if (database) {
      let transaction = database.transaction(storeName, "readwrite") // (1)
      let store = transaction.objectStore(storeName) // (2)
      return store.delete(key) // (3)
    } else {
      console.log("no db")
      return null
    }
  }

  const getKey = async (storeName: string, key: string) => {
    let database = await dbPromise
    return new Promise((resolve, reject) => {
      if (database) {
        let transaction = database.transaction(storeName)
        transaction.onerror = (e: Event) => {
          reject(e)
        }
        //transaction.oncomplete = (e: Event) => {
        //  resolve(e)
        //}
        transaction.onabort = (e: Event) => {
          reject(e)
        }
        let store = transaction.objectStore(storeName)
        let request = store.getKey(key)
        request.onsuccess = (e: Event) => {
          resolve(e)
        }
        request.onerror = (e: Event) => {
          console.log("request error")
          reject(null)
        }
        //return request
      } else {
        console.log("no db " + db + " " + new Date().getTime())
        reject(null)
      }
    })
  }

  const get = async (storeName: string, key: string) => {
    let database = await dbPromise
    return new Promise((resolve, reject) => {
      if (database) {
        let transaction = database.transaction(storeName)

        transaction.onerror = (e: Event) => {
          reject(e)
        }
        //transaction.oncomplete = (e: Event) => {
        //  resolve(e)
        //}
        transaction.onabort = (e: Event) => {
          reject(e)
        }

        let store = transaction.objectStore(storeName)
        let request = store.get(key)
        request.onsuccess = (e: Event) => {
          resolve(e)
        }
        request.onerror = (e: Event) => {
          console.log("request error")
          reject(null)
        }
        //return request
      } else {
        console.log("no db " + db + " " + new Date().getTime())
        reject(null)
      }
    })
  }

  const muteItems = async (items: Item[], addItems) => {
    let database = await dbPromise
    if (database) {
      let transaction = database.transaction("muted")
      transaction.onerror = (e: Event) => {
        //reject(e)
      }
      //transaction.oncomplete = (e: Event) => {
      //  resolve(e)
      //}
      transaction.onabort = (e: Event) => {
        //reject(e)
      }
      let store = transaction.objectStore("muted")

      for (var i = 0; i < items.length; i++) {
        let item = items[i]
        try {
          let promises = []
          if (item.creator && item.creator.id)
            promises.push(
              new Promise((resolve, reject) => {
                let request = store.getKey(item.creator.id)
                request.onsuccess = (e: Event) => {
                  resolve(e)
                }
                request.onerror = (e: Event) => {
                  reject()
                }
              })
            )
          if (item.publisher && item.publisher.id)
            promises.push(
              new Promise((resolve, reject) => {
                let request = store.getKey(item.publisher.id)
                request.onsuccess = (e: Event) => {
                  resolve(e)
                }
                request.onerror = (e: Event) => {
                  reject()
                }
              })
            )
          if (item.publisher && item.publisher.owner)
            promises.push(
              new Promise((resolve, reject) => {
                let request = store.getKey(item.publisher.owner)
                request.onsuccess = (e: Event) => {
                  resolve(e)
                }
                request.onerror = (e: Event) => {
                  reject()
                }
              })
            )

          if (promises.length) {
            Promise.all(promises).then(values => {
              var i = 0
              for (; i < values.length; i++) {
                let res = values[i]
                if (res && res.target.result) {
                  console.log("muted " + res.target.result)
                  break
                }
              }
              if (i == values.length) addItems([item])
            })
          }
        } catch (e) {
          addItems([item])
        }
      }
    }
  }

  const muteComments = async (comments: Comment[], addItems) => {
    for (var i = 0; i < comments.length; i++) {
      let comment = comments[i]
      getKey("muted", comments[i].author.id)
        .then(res => {
          if (res && res.target.result) {
            //console.log("found like " + res.target.result)
          } else {
            console.log("adding comment " + comment)
            addItems([comment])
          }
        })
        .catch(error => {
          console.log("failed to read like ", error)
          console.log("adding comment " + comment)
          addItems([comment])
        })
    }
  }

  const getAllByTs = async (
    storeName: string,
    fromTs: number,
    count: number
  ) => {
    let database = await dbPromise
    return new Promise((resolve, reject) => {
      if (database) {
        let transaction = database.transaction(storeName)
        transaction.onerror = (e: Event) => {
          reject(e)
        }
        transaction.onabort = (e: Event) => {
          reject(e)
        }
        let store = transaction.objectStore(storeName)

        var myIndex = store.index("ts")
        var request = myIndex.getAll(IDBKeyRange.upperBound(fromTs), count)
        request.onsuccess = function (e: Event) {
          console.log(request.result)
          resolve(e)
        }
        request.onerror = (e: Event) => {
          console.log("request error")
          reject(null)
        }
      } else {
        console.log("no db")
        reject(null)
      }
    })
  }

  let currentOffset = 0
  let currentAmount = 0

  const getLikes = async (
    storeName: string,
    offset: number,
    count: number,
    filters: Filters
  ) => {
    let database = await dbPromise
    return new Promise((resolve, reject) => {
      if (database) {
        let ncOffset = 0
        if (
          filters &&
          filters.sort == "amount" &&
          filters.nc &&
          filters.age &&
          filters.age != 0
        )
          ncOffset = Date.now() - filters.age * 3600000

        let transaction = database.transaction(storeName)
        transaction.onerror = (e: Event) => {
          reject(e)
        }
        transaction.onabort = (e: Event) => {
          reject(e)
        }
        let store = transaction.objectStore(storeName)

        var cursor
        if (filters.sort == "date") {
          var items: Item[] = []
          var range = null

          if (filters.channel_ids && filters.channel_ids.length == 1) {
            console.log("channel ids " + filters.channel_ids)
            if (filters.channel_ids[0].indexOf(".") != -1)
              myIndex = store.index("domain-ts")
            else myIndex = store.index("alias-ts")

            console.log("offset " + offset + " " + currentOffset)

            if (offset == 0) {
              console.log("range " + 0 + " " + Number.MAX_VALUE)
              range = IDBKeyRange.bound(
                [filters.channel_ids[0], 0],
                [filters.channel_ids[0], Number.MAX_VALUE],
                true,
                true
              )
              console.log("range " + range)
            } else {
              range = IDBKeyRange.bound(
                [filters.channel_ids[0], 0],
                [filters.channel_ids[0], currentOffset],
                true,
                true
              )
              console.log("range " + range)
            }
            cursor = myIndex.openCursor(range, "prev")
          } else {
            var myIndex = store.index("ts")

            console.log("offset " + offset + " " + currentOffset)

            if (offset > 0 && currentOffset > 0) {
              range = IDBKeyRange.upperBound(currentOffset, true)
              console.log("range " + range)
            }
            cursor = myIndex.openCursor(range, "prev")
          }
          cursor.onsuccess = function (event) {
            var cursor = event.target.result
            if (cursor) {
              /*
                  if (offset && currentOffset.length) {
                    console.log("*offset " + offset + " " + currentOffset)
                    let v = currentOffset
                    currentOffset = ""
                    cursor.continue(v)
                  }*/
              //console.log(cursor.key)
              var item = cursor.value.item
              currentOffset = cursor.value.ts

              if (isFilteredOut(cursor.value, filters)) {
                cursor.continue()
                return
              }

              if (ncOffset && currentOffset < ncOffset) {
                cursor.continue()
                return
              }

              item.amount = cursor.value.amount
              items.push(item)
              if (items.length == count) resolve(items)
              else cursor.continue()
            } else {
              console.log("cursor finished " + items.length)
              resolve(items)
            }
          }
          cursor.onerror = (e: Event) => {
            console.log("request error")
            reject(null)
          }
        } else {
          // sort amount
          var items: Item[] = []
          var range = null
          cursor

          if (filters.channel_ids && filters.channel_ids.length == 1) {
            console.log("channel ids " + filters.channel_ids)
            if (filters.channel_ids[0].indexOf(".") != -1)
              myIndex = store.index("domain-amount-ts")
            else myIndex = store.index("alias-amount-ts")

            console.log("offset " + offset + " " + currentOffset)

            if (offset == 0) {
              console.log("range " + 0 + " " + Number.MAX_VALUE)
              range = IDBKeyRange.bound(
                [filters.channel_ids[0], 0, 0],
                [filters.channel_ids[0], Number.MAX_VALUE, Number.MAX_VALUE],
                true,
                true
              )
              console.log("range " + range)
            } else {
              range = IDBKeyRange.bound(
                [filters.channel_ids[0], 0, 0],
                [filters.channel_ids[0], currentAmount, currentOffset],
                true,
                true
              )
              console.log("range " + range)
            }
            cursor = myIndex.openCursor(range, "prev")
          } else {
            console.log("db: using amount-ts")
            myIndex = store.index("amount-ts")

            if (offset > 0 && currentOffset > 0) {
              range = IDBKeyRange.upperBound(
                [currentAmount, currentOffset],
                true
              )
              console.log("range " + range)
            }
            cursor = myIndex.openCursor(range, "prev")
          }
          cursor.onsuccess = function (event) {
            var cursor = event.target.result
            if (cursor) {
              var item = cursor.value.item
              currentOffset = cursor.value.ts
              currentAmount = cursor.value.amount
              //console.log(
              //  "key " + cursor.key + " value " + JSON.stringify(item)
              //)
              if (isFilteredOut(cursor.value, filters)) {
                cursor.continue()
                return
              }

              if (ncOffset && currentOffset < ncOffset) {
                cursor.continue()
                return
              }

              item.amount = cursor.value.amount
              items.push(item)
              if (items.length == count) resolve(items)
              else cursor.continue()
            } else {
              resolve(items)
            }
          }
          cursor.onerror = (e: Event) => {
            console.log("request error")
            reject(null)
          }
        }
      } else {
        console.log("no db")
        reject(null)
      }
    })
  }

  const isFilteredOut = (value: any, filters: Filters) => {
    if (filters.channel_ids && filters.channel_ids.length > 1) {
      // subscriptions
      if (
        !filters.channel_ids.includes(value.alias) &&
        !filters.channel_ids.includes(value.domain)
      ) {
        return true
      }
    }

    if (filters.tags && filters.tags.length > 0) {
      if (value.item.tags.length == 0) {
        return true
      }

      for (var i = 0; i < filters.tags.length; i++) {
        if (value.item.tags.includes(filters.tags[i]) == false) {
          return true
        }
      }
    }

    if (filters.q && filters.q.length) {
      if (value.search && value.search.indexOf(filters.q.toLowerCase()) != -1)
        return false
      else return true
    }

    if (filters.sort == "amount" && filters.age) {
      const now = new Date().getTime()
      const period = filters.age * 3600000
      /*
        console.log(
          "filter " + (now - value.item.added_date * 1000) + " " + period + " "
        )
*/
      if (
        now - value.ts > period ||
        (filters.nc &&
          value.item.added_date &&
          now - value.item.added_date * 1000 > period)
      )
        return true
    }

    return false
  }

  const getLikedItems = (offset: number, count: number, filters: Filters) => {
    return getLikes("item_likes", offset, count, filters)
  }

  const getLikedAuthors = (offset: number, count: number, filters: Filters) => {
    return getLikes("author_likes", offset, count, filters)
  }

  const getLikedWebsites = (
    offset: number,
    count: number,
    filters: Filters
  ) => {
    return getLikes("website_likes", offset, count, filters)
  }

  const getMutedItems = async (offset: number, count: number) => {
    let database = await dbPromise
    return new Promise((resolve, reject) => {
      if (database) {
        let transaction = database.transaction("muted")
        transaction.onerror = (e: Event) => {
          reject(e)
        }
        transaction.onabort = (e: Event) => {
          reject(e)
        }
        let store = transaction.objectStore("muted")

        var items: Channel[] = []
        var range = null

        var myIndex = store.index("ts")
        console.log("offset " + offset + " " + currentOffset)

        if (offset > 0 && currentOffset > 0) {
          range = IDBKeyRange.upperBound(currentOffset, true)
          console.log("range " + range)
        }
        let cursor = myIndex.openCursor(range, "prev")
        cursor.onsuccess = function (event) {
          var cursor = event.target.result
          if (cursor) {
            console.log(cursor.key)
            var item = cursor.value.meta
            items.push(item)
            currentOffset = cursor.value.ts
            if (items.length == count) resolve(items)
            else cursor.continue()
          } else {
            console.log("cursor finished " + items.length)
            console.log(JSON.stringify(items))
            resolve(items)
          }
        }
        cursor.onerror = (e: Event) => {
          console.log("request error")
          reject(null)
        }
      } else {
        console.log("no db")
        reject(null)
      }
    })
  }

  const clearIndexedDb = async () => {
    let database = await dbPromise
    if (database) database.close()
    var req = indexedDB.deleteDatabase("Smartlike")
    req.onsuccess = function () {
      console.log("Deleted database successfully")
    }
    req.onerror = function () {
      console.log("Couldn't delete database")
    }
    req.onblocked = function () {
      console.log("Couldn't delete database due to the operation being blocked")
    }
  }

  return {
    addKV,
    putKV,
    deleteKV,
    getKey,
    get,
    getLikedItems,
    getLikedAuthors,
    getLikedWebsites,
    getMutedItems,
    muteItems,
    muteComments,
    clearIndexedDb,
  }
})
