type Theme = {
  color: {
    background: {
      primary: string
      secondary: string
      tertiary: string
    }
    text: {
      primary: string
      secondary: string
    }
    border: string
    active: string
    scrollbar: string
    error: string
  }
}

export const lightTheme: Theme = {
  color: {
    background: {
      primary: "#ffffff",
      secondary: "#f6f6f6",
      tertiary: "#f9f9f9",
    },
    text: {
      primary: "#000000",
      secondary: "#696969",
    },
    border: "#eaeaea",
    //active: "#ffd54f",
    active: "rgb(251, 230, 162)",
    scrollbar: "#cecfd0",
    error: "#FF385C",
  },
}

export const darkTheme: Theme = {
  color: {
    background: {
      primary: "#121212",
      secondary: "#1f1f1f",
      tertiary: "#191919",
    },
    text: {
      primary: "#ffffff",
      secondary: "#aaaaaa",
    },
    border: "#363636",
    active: "#ffd54f",
    scrollbar: "#6a6a6a",
    error: "#FF385C",
  },
}
