/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

//import Provider from './src/hooks/context'
import RootLayout from './src/hooks/root'

import {
    getTheme,
    ThemeProvider,
    Theme
} from "./src/hooks/use-theme"
import {
    getUser,
    UserProvider
} from "./src/hooks/use-user"
import {
    ModalProvider
} from "./src/hooks/use-modal"
import Modal from "./src/components/layout/modal"

//export const wrapRootElement = ({ element }) => <RootLayout>{element}</RootLayout>;

export const wrapRootElement = RootLayout
getUser()
getTheme()
//console.log("gatsby-browser")