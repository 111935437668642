export const currencies = [
  { c: "AED", n: "United Arab Emirates Dirham", s: "AED" },
  { c: "AFN", n: "Afghan Afghani", s: "&#1547;" },
  { c: "ALL", n: "Albanian Lek", s: "&#76;&#101;&#107;" },
  { c: "AMD", n: "Armenian Dram", s: "AMD" },
  { c: "ANG", n: "Netherlands Antillean Guilder", s: "&#402;" },
  { c: "AOA", n: "Angolan Kwanza", s: "AOA" },
  { c: "ARS", n: "Argentine Peso", s: "&#36;" },
  { c: "AUD", n: "Australian Dollar", s: "&#36;" },
  { c: "AWG", n: "Aruban Florin", s: "&#402;" },
  { c: "AZN", n: "Azerbaijani Manat", s: "&#1084;&#1072;&#1085;" },
  { c: "BAM", n: "Bosnia-Herzegovina Convertible Mark", s: "&#75;&#77;" },
  { c: "BBD", n: "Barbadian Dollar", s: "&#36;" },
  { c: "BDT", n: "Bangladeshi Taka", s: "BDT" },
  { c: "BGN", n: "Bulgarian Lev", s: "&#1083;&#1074;" },
  { c: "BHD", n: "Bahraini Dinar", s: "BHD" },
  { c: "BIF", n: "Burundian Franc", s: "BIF" },
  { c: "BMD", n: "Bermudan Dollar", s: "&#36;" },
  { c: "BND", n: "Brunei Dollar", s: "&#36;" },
  { c: "BOB", n: "Bolivian Boliviano", s: "&#36;&#98;" },
  { c: "BRL", n: "Brazilian Real", s: "&#82;&#36;" },
  { c: "BSD", n: "Bahamian Dollar", s: "&#36;" },
  { c: "BTN", n: "Bhutanese Ngultrum", s: "BTN" },
  { c: "BWP", n: "Botswanan Pula", s: "&#80;" },
  { c: "BYN", n: "Belarusian Ruble", s: "&#66;&#114;" },
  { c: "BZD", n: "Belize Dollar", s: "&#66;&#90;&#36;" },
  { c: "CAD", n: "Canadian Dollar", s: "&#36;" },
  { c: "CDF", n: "Congolese Franc", s: "CDF" },
  { c: "CHF", n: "Swiss Franc", s: "&#67;&#72;&#70;" },
  { c: "CLF", n: "Chilean Unit of Account (UF)", s: "CLF" },
  { c: "CLP", n: "Chilean Peso", s: "&#36;" },
  { c: "CNH", n: "Chinese Yuan (Offshore)", s: "CNH" },
  { c: "CNY", n: "Chinese Yuan", s: "&#165;" },
  { c: "COP", n: "Colombian Peso", s: "&#36;" },
  { c: "CRC", n: "Costa Rican Colón", s: "&#8353;" },
  { c: "CUC", n: "Cuban Convertible Peso", s: "CUC" },
  { c: "CUP", n: "Cuban Peso", s: "&#8369;" },
  { c: "CVE", n: "Cape Verdean Escudo", s: "CVE" },
  { c: "CZK", n: "Czech Republic Koruna", s: "&#75;&#269;" },
  { c: "DJF", n: "Djiboutian Franc", s: "DJF" },
  { c: "DKK", n: "Danish Krone", s: "&#107;&#114;" },
  { c: "DOP", n: "Dominican Peso", s: "&#82;&#68;&#36;" },
  { c: "DZD", n: "Algerian Dinar", s: "DZD" },
  { c: "EGP", n: "Egyptian Pound", s: "&#163;" },
  { c: "ERN", n: "Eritrean Nakfa", s: "ERN" },
  { c: "ETB", n: "Ethiopian Birr", s: "ETB" },
  { c: "EUR", n: "Euro", s: "&#8364;" },
  { c: "FJD", n: "Fijian Dollar", s: "&#36;" },
  { c: "FKP", n: "Falkland Islands Pound", s: "&#163;" },
  { c: "GBP", n: "British Pound Sterling", s: "&#163;" },
  { c: "GEL", n: "Georgian Lari", s: "GEL" },
  { c: "GGP", n: "Guernsey Pound", s: "&#163;" },
  { c: "GHS", n: "Ghanaian Cedi", s: "&#162;" },
  { c: "GIP", n: "Gibraltar Pound", s: "&#163;" },
  { c: "GMD", n: "Gambian Dalasi", s: "GMD" },
  { c: "GNF", n: "Guinean Franc", s: "GNF" },
  { c: "GTQ", n: "Guatemalan Quetzal", s: "&#81;" },
  { c: "GYD", n: "Guyanaese Dollar", s: "&#36;" },
  { c: "HKD", n: "Hong Kong Dollar", s: "&#36;" },
  { c: "HNL", n: "Honduran Lempira", s: "&#76;" },
  { c: "HRK", n: "Croatian Kuna", s: "&#107;&#110;" },
  { c: "HTG", n: "Haitian Gourde", s: "HTG" },
  { c: "HUF", n: "Hungarian Forint", s: "&#70;&#116;" },
  { c: "IDR", n: "Indonesian Rupiah", s: "&#82;&#112;" },
  { c: "ILS", n: "Israeli New Sheqel", s: "&#8362;" },
  { c: "IMP", n: "Manx pound", s: "&#163;" },
  { c: "INR", n: "Indian Rupee", s: "&#8377;" },
  { c: "IQD", n: "Iraqi Dinar", s: "IQD" },
  { c: "IRR", n: "Iranian Rial", s: "&#65020;" },
  { c: "ISK", n: "Icelandic Króna", s: "&#107;&#114;" },
  { c: "JEP", n: "Jersey Pound", s: "&#163;" },
  { c: "JMD", n: "Jamaican Dollar", s: "&#74;&#36;" },
  { c: "JOD", n: "Jordanian Dinar", s: "JOD" },
  { c: "JPY", n: "Japanese Yen", s: "&#165;" },
  { c: "KES", n: "Kenyan Shilling", s: "KES" },
  { c: "KGS", n: "Kyrgystani Som", s: "&#1083;&#1074;" },
  { c: "KHR", n: "Cambodian Riel", s: "&#6107;" },
  { c: "KMF", n: "Comorian Franc", s: "KMF" },
  { c: "KPW", n: "North Korean Won", s: "&#8361;" },
  { c: "KRW", n: "South Korean Won", s: "&#8361;" },
  { c: "KWD", n: "Kuwaiti Dinar", s: "KWD" },
  { c: "KYD", n: "Cayman Islands Dollar", s: "&#36;" },
  { c: "KZT", n: "Kazakhstani Tenge", s: "&#1083;&#1074;" },
  { c: "LAK", n: "Laotian Kip", s: "&#8365;" },
  { c: "LBP", n: "Lebanese Pound", s: "&#163;" },
  { c: "LKR", n: "Sri Lankan Rupee", s: "&#8360;" },
  { c: "LRD", n: "Liberian Dollar", s: "&#36;" },
  { c: "LSL", n: "Lesotho Loti", s: "LSL" },
  { c: "LYD", n: "Libyan Dinar", s: "LYD" },
  { c: "MAD", n: "Moroccan Dirham", s: "MAD" },
  { c: "MDL", n: "Moldovan Leu", s: "MDL" },
  { c: "MGA", n: "Malagasy Ariary", s: "MGA" },
  { c: "MKD", n: "Macedonian Denar", s: "&#1076;&#1077;&#1085;" },
  { c: "MMK", n: "Myanma Kyat", s: "MMK" },
  { c: "MNT", n: "Mongolian Tugrik", s: "&#8366;" },
  { c: "MOP", n: "Macanese Pataca", s: "MOP" },
  { c: "MRU", n: "Mauritanian Ouguiya", s: "MRU" },
  { c: "MUR", n: "Mauritian Rupee", s: "&#8360;" },
  { c: "MVR", n: "Maldivian Rufiyaa", s: "MVR" },
  { c: "MWK", n: "Malawian Kwacha", s: "MWK" },
  { c: "MXN", n: "Mexican Peso", s: "&#36;" },
  { c: "MYR", n: "Malaysian Ringgit", s: "&#82;&#77;" },
  { c: "MZN", n: "Mozambican Metical", s: "&#77;&#84;" },
  { c: "NAD", n: "Namibian Dollar", s: "&#36;" },
  { c: "NGN", n: "Nigerian Naira", s: "&#8358;" },
  { c: "NIO", n: "Nicaraguan Córdoba", s: "&#67;&#36;" },
  { c: "NOK", n: "Norwegian Krone", s: "&#107;&#114;" },
  { c: "NPR", n: "Nepalese Rupee", s: "&#8360;" },
  { c: "NZD", n: "New Zealand Dollar", s: "&#36;" },
  { c: "OMR", n: "Omani Rial", s: "&#65020;" },
  { c: "PAB", n: "Panamanian Balboa", s: "&#66;&#47;&#46;" },
  { c: "PEN", n: "Peruvian Nuevo Sol", s: "&#83;&#47;&#46;" },
  { c: "PGK", n: "Papua New Guinean Kina", s: "PGK" },
  { c: "PHP", n: "Philippine Peso", s: "&#8369;" },
  { c: "PKR", n: "Pakistani Rupee", s: "&#8360;" },
  { c: "PLN", n: "Polish Zloty", s: "&#122;&#322;" },
  { c: "PYG", n: "Paraguayan Guarani", s: "&#71;&#115;" },
  { c: "QAR", n: "Qatari Rial", s: "&#65020;" },
  { c: "RON", n: "Romanian Leu", s: "&#108;&#101;&#105;" },
  { c: "RSD", n: "Serbian Dinar", s: "&#1044;&#1080;&#1085;&#46;" },
  { c: "RUB", n: "Russian Ruble", s: "&#8381;" },
  { c: "RWF", n: "Rwandan Franc", s: "RWF" },
  { c: "SAR", n: "Saudi Riyal", s: "&#65020;" },
  { c: "SBD", n: "Solomon Islands Dollar", s: "&#36;" },
  { c: "SCR", n: "Seychellois Rupee", s: "&#8360;" },
  { c: "SDG", n: "Sudanese Pound", s: "SDG" },
  { c: "SEK", n: "Swedish Krona", s: "&#107;&#114;" },
  { c: "SGD", n: "Singapore Dollar", s: "&#36;" },
  { c: "SHP", n: "Saint Helena Pound", s: "&#163;" },
  { c: "SLL", n: "Sierra Leonean Leone", s: "SLL" },
  { c: "SOS", n: "Somali Shilling", s: "&#83;" },
  { c: "SRD", n: "Surinamese Dollar", s: "&#36;" },
  { c: "SSP", n: "South Sudanese Pound", s: "SSP" },
  { c: "STN", n: "São Tomé and Príncipe Dobra", s: "STN" },
  { c: "SVC", n: "Salvadoran Colón", s: "&#36;" },
  { c: "SYP", n: "Syrian Pound", s: "&#163;" },
  { c: "SZL", n: "Swazi Lilangeni", s: "SZL" },
  { c: "THB", n: "Thai Baht", s: "&#3647;" },
  { c: "TJS", n: "Tajikistani Somoni", s: "TJS" },
  { c: "TMT", n: "Turkmenistani Manat", s: "TMT" },
  { c: "TND", n: "Tunisian Dinar", s: "TND" },
  { c: "TOP", n: "Tongan Pa'anga", s: "TOP" },
  { c: "TRY", n: "Turkish Lira", s: "&#;" },
  { c: "TTD", n: "Trinidad and Tobago Dollar", s: "&#84;&#84;&#36;" },
  { c: "TWD", n: "New Taiwan Dollar", s: "&#78;&#84;&#36;" },
  { c: "TZS", n: "Tanzanian Shilling", s: "TZS" },
  { c: "UAH", n: "Ukrainian Hryvnia", s: "&#8372;" },
  { c: "UGX", n: "Ugandan Shilling", s: "UGX" },
  { c: "USD", n: "United States Dollar", s: "&#36;" },
  { c: "UYU", n: "Uruguayan Peso", s: "&#36;&#85;" },
  { c: "UZS", n: "Uzbekistan Som", s: "&#1083;&#1074;" },
  { c: "VES", n: "Venezuelan Bolívar Soberano", s: "VES" },
  { c: "VND", n: "Vietnamese Dong", s: "&#8363;" },
  { c: "VUV", n: "Vanuatu Vatu", s: "VUV" },
  { c: "WST", n: "Samoan Tala", s: "WST" },
  { c: "XAF", n: "CFA Franc BEAC", s: "XAF" },
  { c: "XAG", n: "Silver Ounce", s: "XAG" },
  { c: "XAU", n: "Gold Ounce", s: "XAU" },
  { c: "XCD", n: "East Caribbean Dollar", s: "&#36;" },
  { c: "XDR", n: "Special Drawing Rights", s: "XDR" },
  { c: "XOF", n: "CFA Franc BCEAO", s: "XOF" },
  { c: "XPD", n: "Palladium Ounce", s: "XPD" },
  { c: "XPF", n: "CFP Franc", s: "XPF" },
  { c: "XPT", n: "Platinum Ounce", s: "XPT" },
  { c: "YER", n: "Yemeni Rial", s: "&#65020;" },
  { c: "ZAR", n: "South African Rand", s: "&#82;" },
  { c: "ZMW", n: "Zambian Kwacha", s: "ZMW" },
  { c: "ZWL", n: "Zimbabwean Dollar", s: "ZWL" },
]

export const currencies_inc_any = [
  {
    c: "any",
    n: "",
    s: "",
  },
].concat(currencies)

export const getCurrencySymbol = (code: string) => {
  for (var i = 0; i < currencies.length; i++) {
    if (code == currencies[i].c) {
      return currencies[i].s
    }
  }
  return code
}
