import Axios, { AxiosRequestConfig, Canceler } from "axios"
import { User, Item, Channel, Tag, Comment, SearchResult } from "../types"
import { SEND_sign } from "./ext"
import { Filters } from "../hooks/use-filters"

type Sort = "amount" | "score" | "date"

export type ItemFilters = {
  count: number
  offset?: number
  sort?: Sort
  classes?: number[]
  q?: string
  age?: number
  languages?: string[]
  countries?: string[]
  channel_ids?: string[]
  tags?: string[]
  nc?: boolean
}

type ItemQuery = {
  count: ItemFilters["count"]
  offset?: ItemFilters["offset"]
  sort?: ItemFilters["sort"]
  classes?: string
  q?: ItemFilters["q"]
  age?: ItemFilters["age"]
  languages?: string
  countries?: string
  channel_ids?: string
  tags?: string
  kind?: number
  nc?: 1 | 0
}

const serializeItemParams = ({
  classes,
  languages,
  countries,
  channel_ids,
  tags,
  nc,
  mod,
  ...filterParams
}: ItemFilters): ItemQuery => ({
  ...filterParams,
  classes: classes && classes.join(","),
  languages: languages?.length ? languages.join(",") : undefined,
  countries: countries?.length ? countries.join(",") : undefined,
  channel_ids: channel_ids?.length ? channel_ids.join(",") : undefined,
  tags: tags?.length ? tags.join(",") : undefined,
  nc: nc ? 1 : 0,
  mod: mod ? mod : undefined,
})

type ChannelFilters = {
  count: number
  offset?: number
  sort?: Sort
  classes?: number[]
  q?: string
  age?: number
  nc?: boolean
  languages?: string[]
  countries?: string[]
  channel_ids?: string[]
  tags?: string[]
  kind: number
}

type ChannelQuery = {
  count: ChannelFilters["count"]
  offset?: ChannelFilters["offset"]
  sort?: ChannelFilters["sort"]
  q?: ChannelFilters["q"]
  age?: ChannelFilters["age"]
  item_ids?: string
}

const serializeChannelParams = ({
  classes,
  nc,
  languages,
  countries,
  channel_ids,
  tags,
  kind,
  mod,
  ...filterParams
}: ChannelFilters): ItemQuery => ({
  ...filterParams,
  classes: classes && classes.join(","),
  nc: typeof nc === "undefined" ? undefined : nc ? 1 : 0,
  languages: languages?.length ? languages.join(",") : undefined,
  countries: countries?.length ? countries.join(",") : undefined,
  channel_ids: channel_ids?.length ? channel_ids.join(",") : undefined,
  tags: tags?.length ? tags.join(",") : undefined,
  kind: kind,
  mod: mod ? mod : undefined,
})

//process.env.BASE_URL +
const http = Axios.create({
  baseURL: "/api",
})

const fetch = <T>(
  config: Omit<AxiosRequestConfig, "cancelToken">
): Promise<T | undefined> & { cancel: Canceler } => {
  const tokenSource = Axios.CancelToken.source()
  const request = http({ ...config, cancelToken: tokenSource.token })
    .then(response => response.data)
    .catch(console.warn)
  return Object.assign(request, {
    cancel: tokenSource.cancel.bind(tokenSource),
  })
}

type SignedMessage = {
  data: string
  sender: string
  signature: string
}

const sign = (payload: string) => {
  let msg: SignedMessage = {
    data: payload,
    sender: "d40bebba-aa61-8b6a-62b6-cd265df42796",
    signature: "sig",
  }
  return msg
}

export const GET_user = (userId: string) =>
  fetch<User>({
    url: `/v2/users/${userId}`,
    // headers: {
    //     Authorization: `Bearer ${userId}`
    // }
  })

export const GET_items = (params: ItemFilters) =>
  fetch<SearchResult>({
    url: "/v2/items",
    params: serializeItemParams(params),
  })

export const GET_likes = (params: any) =>
  fetch<SearchResult>({
    url: "/v2/likes",
    params: params,
  })

export const GET_item = (itemId: string) =>
  fetch<Item>({
    url: `/v2/items/${itemId}`,
  })

export const GET_comments = (itemId: string, params: any) =>
  fetch<Comment[]>({
    url: `/v2/comments/${itemId}`,
    params: params,
  })

export const GET_donations = (itemId: string, params: any) => {
  return fetch<Comment[]>({
    url: `/v2/donations/${itemId}`,
    params: params,
  })
}

export const GET_channels = (params: ChannelFilters) =>
  fetch<Channel[]>({
    url: "/v2/channels",
    params: serializeChannelParams(params),
  })

export const GET_channel = (channelId: string) =>
  fetch<Channel>({
    url: `/v2/channels/${channelId}`,
  })

export const GET_donation_account = (channelId: string) =>
  fetch<Channel>({
    url: `/v2/donation_account/${channelId}`,
  })

export const GET_subscriptions = (
  channelIds: string,
  currency: string,
  userId: string
) =>
  fetch<SearchResult>({
    url: "/v2/subscriptions",
    params: { channel_ids: channelIds, currency: currency, userid: userId },
  })

export const POST_subscriptions = (userId: string, channelId: string) =>
  fetch<Channel>({
    method: "POST",
    url: `/v2/users/${userId}/subscriptions`,
    headers: {
      authorization: `Bearer ${userId}`,
    },
    data: {
      id: channelId,
    },
  })

export const DELETE_subscription = (userId: string, channelId: string) =>
  fetch<Channel>({
    method: "DELETE",
    url: `/v2/users/${userId}/subscriptions/${channelId}`,
    headers: {
      authorization: `Bearer ${userId}`,
    },
  })

export const PATCH_item = (
  userId: string,
  itemId: string,
  payload: Partial<{ title: string; description: string; is_nsfw: boolean }>
) => {
  SEND_sign(JSON.stringify(payload)).then(response => {
    if (response) {
      console.log(response)
      fetch<Item>({
        method: "PATCH",
        url: `/v2/items/${itemId}`,
        headers: {
          authorization: `Bearer ${userId}`,
        },
        data: JSON.stringify(response),
      })
    }
  })
}

export const PATCH_channel = (
  userId: string,
  channelId: string,
  payload: Partial<{
    title: Channel["title"]
    image: Channel["image"]
    icon: Channel["icon"]
    description: Channel["description"]
    url: Channel["url"]
  }>
) =>
  fetch<Channel>({
    method: "PATCH",
    url: `/v2/channels/${channelId}`,
    headers: {
      authorization: `Bearer ${userId}`,
    },
    data: payload,
  })

export const PATCH_comment = (
  userId: string,
  itemId: string,
  commentId: string,
  payload: { amount: number }
) =>
  fetch<Comment>({
    method: "PATCH",
    url: `/v2/items/${itemId}/comments/${commentId}`,
    headers: {
      authorization: `Bearer ${userId}`,
    },
    data: payload,
  })

export const POST_comments = (
  userId: string,
  itemId: string,
  payload: { amount: number; text: string }
) =>
  fetch<Comment>({
    method: "POST",
    url: `/v2/items/${itemId}/comments`,
    headers: {
      authorization: `Bearer ${userId}`,
    },
    data: payload,
  })

export const POST_comment = (
  userId: string,
  itemId: string,
  commentId: string,
  payload: { amount: number; text: string }
) =>
  fetch<Comment>({
    method: "POST",
    url: `/v2/items/${itemId}/comments/${commentId}`,
    headers: {
      authorization: `Bearer ${userId}`,
    },
    data: payload,
  })

export const GET_tags = (userId: string, itemId: string, q?: string) =>
  fetch<Tag[]>({
    url: `/v2/items/${itemId}/tags`,
    headers: {
      authorization: `Bearer ${userId}`,
    },
    data: q && { q },
  })

export const POST_tags = (
  userId: string,
  itemId: string,
  payload: { id?: string; name?: string }
) =>
  fetch<Tag>({
    method: "POST",
    url: `/v2/items/${itemId}/tags`,
    headers: {
      authorization: `Bearer ${userId}`,
    },
    data: payload,
  })

export const PATCH_tag = (
  userId: string,
  itemId: string,
  tagId: string,
  payload: { vote: boolean }
) =>
  fetch<Tag>({
    method: "PATCH",
    url: `/v2/items/${itemId}/tags/${tagId}`,
    headers: {
      authorization: `Bearer ${userId}`,
    },
    data: payload,
  })

export const POST_itemPledges = (payload: {
  sender: string
  url: string
  amount: number
  creator_name?: string
}) =>
  fetch<{ id: string }>({
    method: "POST",
    url: `/v2/pledges/items`,
    data: payload,
  })

export const POST_channelPledges = (payload: {
  sender: string
  name: string
  amount: number
}) =>
  fetch<{ id: string }>({
    method: "POST",
    url: `/v2/pledges/channels`,
    data: payload,
  })

export const POST_commentPledges = (payload: {
  sender: string
  item_url: string
  amount: number
  text: string
  parent_dht_key?: string
}) =>
  fetch<{ id: string }>({
    method: "POST",
    url: `/v2/pledges/comments`,
    data: payload,
  })

export const PATCH_commentPledges = (payload: {
  sender: string
  item_url: string
  amount: number
  dht_key: string
}) =>
  fetch<{ id: string }>({
    method: "PATCH",
    url: `/v2/pledges/comments`,
    data: payload,
  })
