import { FunctionComponent, createElement } from "react"
import { useStyle } from "../hooks/use-style"
import React from "react"
import { lightTheme, darkTheme } from "../services/theme"
import {
  Icon,
  IconDefinition,
  IconLookup,
} from "@fortawesome/fontawesome-svg-core"
import { icons } from "react-icons/lib"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import ButterToast from "butter-toast"

type Props = {
  icon: IconLookup
  from: string
  message: string
}

const StatusNotification: FunctionComponent<Props> = ({
  icon,
  from,
  message,
}) => {
  return (
    <div className="toast">
      <div className="img-container">
        <FontAwesomeIcon
          style={{
            width: "30px",
            height: "30px",
            color: "#bbbbbb",
            margin: "auto",
          }}
          icon={icon}
        />
      </div>
      <div className="message">
        <div className="creator">{from}</div>
        <div className="text">{message}</div>
      </div>

      <style jsx>{`
        .toast {
          width: 350px;
          height: 100px;
          padding: 10px;
          background-color: #333333;
          display: table;
        }
        .img-container {
          display: table-cell;
          height: 100%;
          width: 60px;
          vertical-align: middle;
          margin: auto;
          text-align: center;
        }
        .img {
          height: 50px;
          width: 50px;
        }
        .icon {
          height: 50px;
          width: 50px;
          color: #bbbbbb;
        }
        .message {
          display: table-cell;
          vertical-align: middle;
        }
        .creator {
          color: #ffffff;
          font-weight: 400;
          margin-bottom: 5px;
        }
        .text {
          color: #bbbbbb;
          font-weight: 400;
        }
      `}</style>
    </div>
  )
}

export const showToast = (
  iconDef: IconLookup,
  from: string,
  message: string
) => {
  console.log("showToast")
  const c = React.createElement(
    StatusNotification,
    {
      icon: iconDef,
      from: from,
      message: message,
    },
    null
  )
  ButterToast.raise({
    content: c,
    timeout: 3000,
  })
}

export default StatusNotification
