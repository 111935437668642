module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"smartlike","short_name":"smartlike","start_url":"/","background_color":"#f5deb3","theme_color":"#f5deb3","display":"standalone","icons":[{"src":"/favicons/smartlike_192.png","sizes":"192x192","type":"image/png"},{"src":"/favicons/smartlike_512.png","sizes":"512x512","type":"image/png"}],"share_target":{"action":"/integration/","method":"GET","enctype":"application/x-www-form-urlencoded","params":{"title":"title","text":"text","url":"url"}},"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":null},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-modal-routing-3/gatsby-browser.js'),
      options: {"plugins":[],"appElement":"#___gatsby","modalProps":{"style":{"overlay":{"zIndex":99999},"content":{"backgroundColor":"transparent","border":"none","zIndex":200}}}},
    },{
      plugin: require('../node_modules/gatsby-plugin-use-query-params/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
