import { Filters } from "../hooks/use-filters"
import { Item, Comment, Channel, Gateway } from "../types"

let rate = 1.0

export const setCurrencyConverterRate = (r: number) => {
  rate = r
}

export const convertContentItems = (response, filters: Filters) => {
  if (!response || !response.hits) return []
  //console.log("convertContentItems rate " + rate)
  let r = response.hits.hits
  let items: Item[] = []
  let age = filters.age
  for (var i = 0; i < r.length; i++) {
    let item = r[i]["_source"]
    item["published_at"] = item["added_date"]
    item.id = r[i]["_id"]
    item.comments = []
    item.score = 0
    item.is_nsfw = false

    if ("publisher_info" in item) {
      item.publisher = {
        id: item["publisher"],
        title: item.publisher_info?.title,
        name: item["publisher"],
        description: "",
        image: "",
        icon: item["publisher_info"]["icon"],
        url: item["publisher"],
        amount: 0,
        items_count: 0,
      }
    }

    if (item.author_info) {
      item.creator = {
        id: item["author_info"]["id"],
        title: item["author_info"]["title"],
        name: item["author_info"]["name"],
        image: "",
        icon: item["author_info"]["avatar"],
        description: "",
        url: item["author_info"]["home"],
        amount: 0,
      }
    } else item.creator = null

    if (filters.sort == "amount" && age != 0) {
      if (item["amount_" + age] != 0) {
        item.amount = item["amount_" + age]
        item.donate = item["donate_" + age]
      } else {
        if (item["amount_168"] != 0) {
          age = 168
        } else if (item["amount_720"] != 0) {
          age = 720
        } else if (item["amount_8760"] != 0) {
          age = 8760
        } else {
          age = 0
        }
        /*console.log(
          "************************* older data detected " +
            filters.age +
            " -> " +
            age
        )*/
        item.older = age
        if (age != 0) {
          item.amount = item["amount_" + age]
          item.donate = item["donate_" + age]
        }
      }
    }

    if (filters.mod == 0) {
      item.btags = undefined
    } else if ("flag_stats" in item /* && item.flag_stats.length > 0*/) {
      sortModerationData(item)
    }

    item.amount = item.amount * rate
    item.donate = item.donate * rate
    items.push(item)
  }
  //console.log(items)
  return items
}

export const convertComments = (response, filters: Filters) => {
  if (!response || !response.hits) return []
  //console.log("rate " + rate)
  let r = response.hits.hits
  let comments: Comment[] = []
  for (var i = 0; i < r.length; i++) {
    let item = r[i]["_source"]
    let comment = {
      id: r[i]["_id"],
      dht_key: r[i]["_id"],
      text: item.text,
      amount: item.amount,
      published_at: item.edited ? item.edited : item.added_date,
      comments: [],
      comments_count: item.replies,
      score: item.score,
      edited: item.edited ? "edited" : "",
      flags: item.flags,
      flag_stats: item.flag_stats,
      mf: item.mf,
      mfg: item.gmf,
      ms: item.ms,
      author: {
        id: item.userid,
        title: item.title,
        name: item.name,
        description: "",
        image: item.avatar,
        icon: "",
        url: "",
        amount: 0,
        items_count: 0,
      },
      read: item.read,
    }
    if (comment.author.name && comment.author.name.length != 0)
      comment.author.url = comment.author.id
    //"@" + comment.author.name
    else comment.author.url = comment.author.id

    comment.amount = comment.amount * rate

    if (filters.mod == 0) {
      /*comment.btags = undefined*/
    } else if ("flag_stats" in comment /* && comment.flag_stats.length > 0*/) {
      sortModerationData(comment)
    }

    comments.push(comment)
  }
  //console.log(comments)
  return comments
}

export const convertChannels = (response, filters: Filters) => {
  if (!response || !response.hits) return []

  let channels: Channel[] = []
  let r = response.hits.hits
  let age = filters.age
  for (var i = 0; i < r.length; i++) {
    let item = r[i]["_source"]
    let ch: Channel = item
    ch.id = r[i]["_id"]
    if (ch.alias && ch.alias?.indexOf("#") != -1) {
      let p = ch.alias?.split("#")
      if (p?.length == 2) {
        ch.alias_name = p[0]
        ch.alias_host = p[1]
        ch.name = ch.alias_name
      }
    } else {
      ch.alias_name = null
    }

    /*if (filters.sort == "amount" && "sort" in r[i] && r[i]["sort"].length > 0) {
      ch.amount = r[i]["sort"][0]
    }*/

    if (filters.sort == "amount" && age != 0) {
      if (item["amount_" + age] != 0) {
        ch.amount = item["amount_" + age]
        //ch.donate = item["donate_" + age]
      } else {
        if (item["amount_168"] != 0) {
          age = 168
        } else if (item["amount_720"] != 0) {
          age = 720
        } else if (item["amount_8760"] != 0) {
          age = 8760
        } else {
          age = 0
        }
        /*console.log(
          "************************* older data detected " +
            filters.age +
            " -> " +
            age
        )*/
        ch.older = age
        if (age != 0) {
          ch.amount = item["amount_" + age]
          //ch.donate = item["donate_" + age]
        }
      }
    }

    if ("owner" in item) ch.owner = item["owner"]

    if ("publisher_info" in item)
      item.publisher = {
        id: item["publisher_info"]["title"],
        title: item["publisher_info"]["title"],
        name: item["publisher_info"]["title"],
        description: "",
        image: "",
        icon: item["publisher_info"]["icon"],
        url: item["publisher_info"]["title"],
        amount: 0,
        items_count: 0,
      }

    if (filters.mod == 0) {
      item.btags = undefined
    } else if ("flag_stats" in item /* && item.flag_stats.length > 0*/) {
      sortModerationData(item)
    }

    ch.amount = ch.amount * rate
    channels.push(ch)
  }
  return channels
}

export const convertChannel = response => {
  if (!response || !response.hits) return null

  let r = response.hits.hits
  for (var i = 0; i < r.length; i++) {
    let item = r[i]["_source"]
    let ch: Channel = item
    ch.id = r[i]["_id"]

    if (ch.alias && ch.alias?.indexOf("#") != -1) {
      let p = ch.alias?.split("#")
      if (p?.length == 2) {
        ch.alias_name = p[0]
        ch.alias_host = p[1]
        ch.name = ch.alias_name
      }
    } else {
      ch.alias_name = null
    }

    ch.amount = ch.amount * rate
    return ch
  }
}

export const convertGateways = response => {
  if (
    !response ||
    !response.hits ||
    !response.hits.hits ||
    response.hits.hits.length == 0
  )
    return []

  let r = response.hits.hits
  let new_items: Gateway[] = []
  for (var i = 0; i < r.length; i++) {
    let item = r[i]["_source"]
    //for (var j = 0; j < item["gateways"].length; j++) {
    let g = item["gateways"][0]
    let gateway: Gateway = {
      id: r[i]["_id"],
      name: r[i]["_id"],
      title: item["title"],
      description: item["description"],
      image: item["image"],
      icon: item["icon"],
      gateway_description: g["description"],
      url: item["url"],
      amount: item["amount"],
      address: g["address"],
      country: g["country"],
      currency: g["currency"],
      processor: g["processor"],
      owner: item["owner"],
      added_date: item["added_date"],
      comments_count: item["comments_count"],
      tags: [],
    }

    if ("alias" in item && item["alias"].indexOf("#") != -1) {
      let p = item["alias"].split("#")
      if (p?.length == 2) {
        gateway["alias_name"] = p[0]
        gateway["alias_host"] = p[1]
      }
    } else {
      gateway["alias_name"] = null
    }

    if ("tags" in item) gateway["tags"] = item["tags"]
    else gateway["tags"] = []

    gateway.amount = gateway.amount * rate
    new_items.push(gateway)
    //}
  }
  return new_items
}

const sortModerationData = item => {
  item.green_flags = []
  item.yellow_flags = []
  item.red_flags = []
  for (const [f, s] of Object.entries(item.flag_stats)) {
    const flag = parseInt(f)
    if (s.m == 0) {
      item.yellow_flags.push(flag)
    } else {
      if (s.m == 1) {
        item.green_flags.push(flag)
      } else if (s.m == -1) {
        item.red_flags.push(flag)
      }
      const signum = Math.sign(s.p - s.c)
      if (signum != s.m) item.flag_stats[f].d = true
      else item.flag_stats[f].d = false
    }
  }
}

export const prepareUrl = (url: string) => {
  url = url.replace("//www.", "//")
  url = url.replace("//m.", "//")
  url = url.replace("//mobile.", "//")

  var parser = document.createElement("a")
  parser.href = url
  if (
    parser.protocol.indexOf("chrome") == 0 ||
    parser.protocol.indexOf("resource") == 0
  )
    return { preparedUrl: null, publisher: null }

  if (parser.hostname == "youtu.be") {
    let pathname = new URL(url).pathname
    if (pathname && pathname.length > 1) {
      parser.hostname = "youtube.com"
      url =
        parser.protocol +
        "//" +
        parser.hostname +
        "/watch?v=" +
        pathname.substring(1)
    }
  } else if (parser.hostname == "youtube.com") {
    let u = new URL(url)
    let vid = u.searchParams.get("v")
    if (u.pathname == "/watch" && vid)
      url = parser.protocol + "//" + parser.hostname + "/watch?v=" + vid
  }

  return { preparedUrl: url, publisher: parser.hostname }
}
