// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-allcomments-tsx": () => import("./../../../src/pages/allcomments.tsx" /* webpackChunkName: "component---src-pages-allcomments-tsx" */),
  "component---src-pages-audit-tsx": () => import("./../../../src/pages/audit.tsx" /* webpackChunkName: "component---src-pages-audit-tsx" */),
  "component---src-pages-authors-tsx": () => import("./../../../src/pages/authors.tsx" /* webpackChunkName: "component---src-pages-authors-tsx" */),
  "component---src-pages-channel-tsx": () => import("./../../../src/pages/channel.tsx" /* webpackChunkName: "component---src-pages-channel-tsx" */),
  "component---src-pages-comments-tsx": () => import("./../../../src/pages/comments.tsx" /* webpackChunkName: "component---src-pages-comments-tsx" */),
  "component---src-pages-donate-tsx": () => import("./../../../src/pages/donate.tsx" /* webpackChunkName: "component---src-pages-donate-tsx" */),
  "component---src-pages-feedback-tsx": () => import("./../../../src/pages/feedback.tsx" /* webpackChunkName: "component---src-pages-feedback-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-integration-tsx": () => import("./../../../src/pages/integration.tsx" /* webpackChunkName: "component---src-pages-integration-tsx" */),
  "component---src-pages-item-tsx": () => import("./../../../src/pages/item.tsx" /* webpackChunkName: "component---src-pages-item-tsx" */),
  "component---src-pages-login-tsx": () => import("./../../../src/pages/login.tsx" /* webpackChunkName: "component---src-pages-login-tsx" */),
  "component---src-pages-moderators-tsx": () => import("./../../../src/pages/moderators.tsx" /* webpackChunkName: "component---src-pages-moderators-tsx" */),
  "component---src-pages-muted-tsx": () => import("./../../../src/pages/muted.tsx" /* webpackChunkName: "component---src-pages-muted-tsx" */),
  "component---src-pages-rates-tsx": () => import("./../../../src/pages/rates.tsx" /* webpackChunkName: "component---src-pages-rates-tsx" */),
  "component---src-pages-reload-tsx": () => import("./../../../src/pages/reload.tsx" /* webpackChunkName: "component---src-pages-reload-tsx" */),
  "component---src-pages-settings-tsx": () => import("./../../../src/pages/settings.tsx" /* webpackChunkName: "component---src-pages-settings-tsx" */),
  "component---src-pages-subscriptions-tsx": () => import("./../../../src/pages/subscriptions.tsx" /* webpackChunkName: "component---src-pages-subscriptions-tsx" */),
  "component---src-pages-supporters-tsx": () => import("./../../../src/pages/supporters.tsx" /* webpackChunkName: "component---src-pages-supporters-tsx" */),
  "component---src-pages-websites-tsx": () => import("./../../../src/pages/websites.tsx" /* webpackChunkName: "component---src-pages-websites-tsx" */)
}

