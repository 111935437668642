import { useState, useEffect } from "react"

export const useMounted = () => {
    const [isMounted, setMounted] = useState(false)

    useEffect(() => {
        if (!isMounted) {
            const timeout = setTimeout(() => {
                setMounted(true)
            })
            return () => {
                clearTimeout(timeout)
            }
        }
    }, [isMounted])

    return isMounted
}
