import {
  FunctionComponent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react"
import { CSSTransition } from "react-transition-group"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faTimes } from "@fortawesome/pro-regular-svg-icons"
import { darkTheme, lightTheme } from "../../services/theme"
import { useModal } from "../../hooks/use-modal"
import { useStyle } from "../../hooks/use-style"
import React from "react"

const Modal: FunctionComponent = () => {
  const { content, setContent } = useModal()
  const hasContent = Boolean(content)
  const [contentCopy, setContentCopy] = useState(content)
  const contentRef = useRef<HTMLDivElement>(null)
  const hideContent = useCallback(() => setContent(null), [])

  const onClick = useCallback((event: MouseEvent) => {
    if (
      contentRef.current &&
      !contentRef.current.contains(event.target as Node)
    ) {
      hideContent()
    }
  }, [])

  const onKeyDown = useCallback((event: KeyboardEvent) => {
    if (event.key == "Escape") {
      hideContent()
    }
  }, [])

  const css = useStyle(theme => ({
    backgroundColor:
      theme === "light"
        ? lightTheme.color.background.primary
        : darkTheme.color.background.secondary,
    buttonColor:
      theme === "light"
        ? darkTheme.color.text.primary
        : lightTheme.color.text.primary,
    buttonBackgroundColor:
      theme === "light"
        ? darkTheme.color.background.secondary
        : lightTheme.color.background.secondary,
    textColor:
      theme === "light"
        ? lightTheme.color.text.primary
        : darkTheme.color.text.primary,
  }))

  useEffect(() => {
    if (content) {
      setContentCopy(typeof content === "object" ? { ...content } : content)
    } else {
      setTimeout(() => setContentCopy(null), 300)
    }
  }, [content])

  useEffect(() => {
    document.body.style.overflow = contentCopy ? "hidden" : ""
    document.body.style.marginRight = contentCopy ? "8px" : ""
    window.addEventListener("keydown", onKeyDown)
    return () => window.removeEventListener("keydown", onKeyDown)
  }, [contentCopy])

  const duration = 300

  return (
    <CSSTransition
      in={hasContent}
      unmountOnExit
      timeout={duration}
      classNames="modal"
      onClick={onClick}
    >
      <div className="modal">
        <div className="wrapper">
          <div className="content" ref={contentRef}>
            {/*
            <button onClick={hideContent}>
              <FontAwesomeIcon icon={faTimes} size="lg" />
              </button>*/}
            <section>{contentCopy}</section>
          </div>
        </div>

        <style jsx>{`
          .modal {
            position: fixed;
            top: 0;
            left: 0;
            height: 100vh;
            width: 100vw;
            z-index: 100;
            display: flex;
            flex-direction: column;
            justify-content: center;
            background-color: rgba(0, 0, 0, 0.2);
            color: ${css.textColor};
            transition: 200ms color;
          }
          .wrapper {
            position: relative;
            overflow: auto;
            -webkit-overflow-scrolling: touch;
            padding-top: 2em;
            padding-bottom: 2em;
          }
          button {
            width: 90px;
            padding: 3px;
            margin: 5px;
          }
          /*
          button {
            position: absolute;
            top: -1em;
            right: -1em;
            border-radius: 50%;
            border: none;
            height: 2em;
            width: 2em;
            background-color: ${css.buttonBackgroundColor};
            color: ${css.buttonColor};
            transition: 200ms color, 200ms background-color;
            cursor: pointer;
          }*/
          @media (max-width: 700px) {
            button {
              top: 0.25em;
              right: 0.25em;
            }
          }
          .content {
            position: relative;
            margin: 0 auto;
            padding: 1em;
            background-color: ${css.backgroundColor};
            transition: 200ms background-color;
            border-radius: 0.3em;
            max-width: 640px;
            font-family: Roboto;
          }
          @media (max-width: 700px) {
            .content {
              margin: 0 auto;
            }
          }
          /*
          .modal-enter .background {
            opacity: 0;
          }
          .modal-enter-active .background {
            opacity: 1;
            transition: opacity ${duration}ms;
          }
          .modal-exit .background {
            opacity: 1;
          }
          .modal-exit-active .background {
            opacity: 0;
            transition: opacity ${duration}ms;
          }
          .modal-enter .content {
            transform: translateY(2em);
            opacity: 0;
          }
          .modal-enter-active .content {
            transform: translateY(0);
            opacity: 1;
            transition: transform ${duration}ms, opacity ${duration}ms;
          }
          .modal-exit .content {
            transform: translateY(0);
            opacity: 1;
          }
          .modal-exit-active .content {
            transform: translateY(2em);
            opacity: 0;
            transition: transform ${duration}ms, opacity ${duration}ms;
          }
          */
        `}</style>
      </div>
    </CSSTransition>
  )
}

export default Modal
