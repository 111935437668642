import { faInfoCircle } from "@fortawesome/pro-regular-svg-icons"
import { useCallback, useEffect, useRef, useState } from "react"
import cookie from "react-cookies"
import { unstable_batchedUpdates } from "react-dom"
import { showToast } from "../components/status-notification"
import { getCurrencySymbol } from "../etc/currencies"
import * as auth from "../services/auth"
import { convertChannel, setCurrencyConverterRate } from "../services/content"
import { arr2hex, arr2base64, getPublicKey } from "../services/crypto"
import { GET_channel, GET_subscriptions } from "../services/http"
import {
  getProfile,
  pendingCall,
  resetPendingCall,
  rpc,
} from "../services/node"
import { Bookmark, Channel, Item, User } from "../types"
import { createSingletonHook } from "./create-singleton-hook"
import { useIndexedDB } from "./use-indexeddb"
import { generateDMKeys } from "../services/crypto"

let defaultBookmarks = [
  ["charity"],
  ["book"],
  ["music", "video"],
  ["music", "video", "pop"],
  ["news"],
  ["recipe"],
  ["space"],
  ["technology"],
]

let infoChannel = {
  title: "Smartlike Blog",
  description: "News and guides",
  image: "https://smartlike.org/favicons/android-chrome-192x192.png",
  icon: "https://smartlike.org/favicons/favicon-32x32.png",
  language: "en",
  country: "",
  url: "blog.smartlike.org",
  tags: [],
  published_date: null,
  added_date: 1600159062,
  gateways: [
    {
      country: "Austria",
      address: "donate@smartlike.org",
      min_amount: 0,
      max_amount: 1000,
      description: "Thank you for supporting Smartlike development",
      currency: "EUR",
      processor: "PayPal",
      timestamp: 1600173549000,
    },
  ],
  amount_24: 0,
  amount_168: 0,
  amount_720: 0,
  amount_8760: 0,
  amount: 0,
  count_24: 0,
  count_168: 0,
  count_720: 0,
  count_8760: 0,
  count: 0,
  owner: "d40bebba-aa61-8b6a-62b6-cd265df42796",
  id: "blog.smartlike.org",
  notifications: true,
  last_viewed: 0,
  num_views: 0,
  recent_content: 0,
}

/* accountState
 -10 - local
 0 - initial
 10 - global
 15 - password entered
 20 - donation notice agreed
 30 - not enough funds
*/
export const [useUser, UserProvider] = createSingletonHook(
  (initialUser: User | null) => {
    const [user, setUser] = useState(initialUser)
    const [accountState, setAccountState] = useState(0)
    const [isLoggingIn, setLoggingIn] = useState(false)
    const [loggedIn, setLoggedIn] = useState(false)
    const [itemLikes, setItemLikes] = useState<string[]>([])
    const [channelLikes, setChannelLikes] = useState<string[]>([])
    const [commentLikes, setCommentLikes] = useState<string[]>([])
    const { addKV, putKV, getKey, get, clearIndexedDb } = useIndexedDB()
    const [showBookmarks, setShowBookmarks] = useState(false)
    const [showMoreSubscriptions, setShowMoreSubscriptions] = useState(false)
    const [profileSynced, setProfileSynced] = useState(false)

    let lastSubscriptionQuery = 0

    const login = useCallback(async (userId: string) => {
      console.log("login")
      setLoggingIn(true)
      const user = await auth.login(userId)
      unstable_batchedUpdates(() => {
        setLoggingIn(false)
        setUser(user)
      })
      return !!user
    }, [])

    const login1 = useCallback(
      async (secretPhrase: string) => {
        cachedSecret = secretPhrase
        //if (user?.account_type == "unset") {
        //user.account_type = "global"
        //saveUser(user)
        //}
        const [pk, pkHex] = getPublicKey(secretPhrase)
        let user: User = loadUser(arr2hex(pk), arr2base64(pk))
        //user.account_type = "global"
        localStorage.setItem("currentUser", user.pk)

        getProfile(secretPhrase, function (res, pk) {
          if (res.status == "ok") {
            unstable_batchedUpdates(async () => {
              if (res.data.dns.name.length == 0) res.data.dns.name = res.data.id
              let newUser: User = {
                ...user,
                id: res.data.id,
                state: res.data.state,
                /*pk: arr2hex(pk),
                pk_base64: arr2base64(pk),*/
                balance: {
                  ...res.data.balance,
                  currency_symbol: getCurrencySymbol(res.data.balance.currency),
                },
                profile: res.data.dns,
                support: res.data.support,
                account_type: "global",
              }
              setUser(newUser)
              setLoggedIn(true)
              if (res.data.state == 0) {
                // Create keys for direct messaging
                showToast(
                  faInfoCircle,
                  "Smartlike network",
                  "Generating encryption keys for end-to-end messaging"
                )
                const [publicKey, encryptedPrivateKey, iv] =
                  await generateDMKeys(secretPhrase)
                if (publicKey && publicKey.length) {
                  let msg = {
                    dm_public_key: publicKey,
                    dm_encrypted_private_key: encryptedPrivateKey,
                    dm_iv: iv,
                  }
                  console.log("iv " + iv)

                  rpc(
                    "publish_rsa_keys",
                    JSON.stringify(msg),
                    newUser,
                    secretPhrase,
                    function (res) {
                      if (res.status == "ok") {
                        setUser(
                          user =>
                            user && {
                              ...user,
                              dm_keys: {
                                userid: user.id,
                                dm_public_key: publicKey,
                                dm_encrypted_private_key: encryptedPrivateKey,
                                dm_iv: iv,
                              },
                            }
                        )
                      }
                    }
                  )
                }
              } else if (user.dm_keys.dm_public_key == "") {
                rpc("get_dm_keys", "", user, getCachedSecret(), response => {
                  console.log("get_dm_keys " + response)
                  if (response.status == "ok" && response.data.length) {
                    try {
                      const keys = JSON.parse(response.data)
                      if (keys) {
                        //console.log("keys " + keys.dm_public_key)

                        setUser(
                          user =>
                            user && {
                              ...user,
                              dm_keys: {
                                userid: user.id,
                                dm_public_key: keys.dm_public_key,
                                dm_encrypted_private_key:
                                  keys.dm_encrypted_private_key,
                                dm_iv: keys.dm_iv,
                              },
                            }
                        )
                      }
                    } catch (e) {
                      console.log("failed to parse filters")
                    }
                  } else console.log("failed to get dm keys")
                })
              }
            })
            console.log("profile " + res.data)
          } else if (res.status == "error") {
            if (res.data == "Unknown pk") {
              console.log("unknown pk. " + accountState + " " + pk)
              if (pk != user.pk) setAccountState(10)
              setUser(user)
            }
          }
        })
      },
      [user, accountState]
    )

    const scheduleBalanceQuery = async (numTries: number) => {
      if (cachedSecret == null || cachedSecret.length == 0) {
        console.log("log in first")
        return
      }
      var oldBalance = 0
      if (user && user.balance.balance > 0) oldBalance = user.balance.balance
      console.log("scheduleBalanceQuery " + oldBalance)
      getProfile(cachedSecret, function (res, pk) {
        if (res.status == "ok") {
          setUser(
            user =>
              user && {
                ...user,
                id: res.data.id,
                pk: arr2hex(pk),
                pk_base64: arr2base64(pk),
                balance: {
                  ...res.data.balance,
                  currency_symbol: getCurrencySymbol(res.data.balance.currency),
                },
                profile: res.data.dns,
                support: res.data.support,
              }
          )
          console.log("profile " + res.data)
          console.log("balance " + res.data.balance.balance + " " + numTries)
          if (res.data.balance.balance != oldBalance) return
        }

        if (numTries > 0)
          setTimeout(function () {
            scheduleBalanceQuery(numTries - 1)
          }, 3000)
      })
    }

    const isLoggedIn = useCallback(() => {
      return cachedSecret != null
    }, [])

    const logout = useCallback(() => {
      //auth.logout()
      setUser(null)
      cachedUser = null
      cachedSecret = null
    }, [])

    const updateProfile = useCallback((profile: Channel) => {
      console.log("updateProfile")
      setUser(user => user && { ...user, profile })
    }, [])

    const setType = useCallback((type: string) => {
      setUser(user => user && { ...user, account_type: type })
    }, [])

    useEffect(() => {
      cachedUser = user
      if (user) {
        saveUser(user)
      } else {
        console.log("loading profile")
        let showBookmarks_str = localStorage.getItem("showBookmarks")
        if (showBookmarks_str)
          setShowBookmarks(showBookmarks_str == "true" ? true : false)
        else setShowBookmarks(true)

        let showMoreSubscriptions_str = localStorage.getItem(
          "showMoreSubscriptions"
        )
        if (showMoreSubscriptions_str)
          setShowMoreSubscriptions(
            showMoreSubscriptions_str == "true" ? true : false
          )

        let state = localStorage.getItem("accountState")
        if (state) setAccountState(parseInt(state))

        let currentUser_str = localStorage.getItem("currentUser")
        let loaded = loadUser(currentUser_str ? currentUser_str : "local", "")
        setUser(loaded)
        if (user) updateSubscriptions(loaded.subscriptions)
      }
    }, [user])

    useEffect(() => {
      if (accountState != 0)
        localStorage.setItem("accountState", accountState + "")
      if (pendingCall != null && accountState < 0) {
        pendingCall.callBack({ status: "ok" })
        resetPendingCall()
      }
    }, [accountState])

    useEffect(() => {
      if (user) localStorage.setItem("showBookmarks", showBookmarks + "")
    }, [user, showBookmarks])

    useEffect(() => {
      if (user)
        localStorage.setItem(
          "showMoreSubscriptions",
          showMoreSubscriptions + ""
        )
    }, [user, showMoreSubscriptions])

    useEffect(() => {
      let key = getCachedSecret()
      if (
        user &&
        pendingCall &&
        user.id !== undefined &&
        user.id !== "user_id" &&
        key != null &&
        key != ""
      ) {
        //console.log("pending call: " + user.id + " " + pendingCall.method)
        rpc(
          pendingCall.method,
          pendingCall.data,
          user,
          key,
          pendingCall.callBack
        )
        resetPendingCall()
      }
    }, [user, loggedIn])

    const loadUser = useCallback((pk: string, pk_base64: string) => {
      let users_str = localStorage.getItem("users")
      let users
      if (users_str) {
        try {
          users = new Map(JSON.parse(users_str))
        } catch (e) {
          console.log("failed to parse users")
          users = new Map()
        }
      } else {
        users = new Map()
      }
      let user
      if (pk && users.has(pk)) {
        user = users.get(pk)
      } else {
        user = {
          id: "",
          pk: pk,
          pk_base64: pk_base64,
          account_type: pk_base64.length ? "global" : "local",
          balance: {
            balance: 0,
            donation_balance: 0,
            credit_line: 0,
            currency_symbol: "",
          },
          profile: {
            id: "",
            name: "",
            title: "",
            description: "",
            image: "",
            icon: "",
            amount: 0,
            items_count: 0,
            url: "",
            country: "",
            language: "",
            timestamp: 0,
            thanks: "",
          },
          subscriptions: [infoChannel],
          bookmarks: [],
          displayed_currency: "USD",
          displayed_currency_rate: 1.0,
          displayed_currency_symbol: "&#36;",
          state: 0,
          like_value: 0.01,
          post: 0,
          post_read: 0,
          dm_keys: {
            userid: "",
            dm_public_key: "",
            dm_encrypted_private_key: "",
            dm_iv: "",
          },
        }

        for (var i = 0; i < defaultBookmarks.length; i++) {
          user.bookmarks.push({
            id: i,
            tags: defaultBookmarks[i].slice(),
            numViews: 1,
            lastViewed: Math.floor(Date.now() / 1000),
          })
        }

        unstable_batchedUpdates(() => {
          setItemLikes([])
          setChannelLikes([])
          setCommentLikes([])
        })

        users.set(pk, user)
        localStorage.setItem(
          "users",
          JSON.stringify(Array.from(users.entries()))
        )
      }
      return user
    }, [])

    const saveUser = useCallback((user: User) => {
      const currentUser = user.pk.length > 0 ? user.pk : "local"
      localStorage.setItem("user", JSON.stringify(user))
      let users_str = localStorage.getItem("users")
      let users
      if (users_str) {
        try {
          users = new Map(JSON.parse(users_str))
        } catch (e) {
          users = new Map()
        }
        users.set(currentUser, user)
      } else {
        users = new Map()
      }
      localStorage.setItem("users", JSON.stringify(Array.from(users.entries())))
      localStorage.setItem("currentUser", currentUser)
    }, [])

    const preapareSearch = (item: Item) => {
      return (
        (item.url ? item.url + " " : "") +
        (item.title.length ? item.title.toLocaleLowerCase() + " " : "") +
        (item.description.length
          ? item.description.toLocaleLowerCase() + " "
          : "") +
        (item.creator && item.creator.title && item.creator.title.length
          ? item.creator.title.toLocaleLowerCase() + " "
          : "") +
        (item.publisher && item.publisher.id && item.publisher.id.length
          ? item.publisher.id.toLocaleLowerCase() + " "
          : "") +
        (item.tags.length ? item.tags.join(" ").toLocaleLowerCase() : "")
      )
    }

    const updateItemLikes = useCallback(
      (item: Item, amount: number) => {
        //console.log("storing like")

        setItemLikes(itemLikes =>
          itemLikes.includes(item.url) ? itemLikes : [...itemLikes, item.url]
        )

        item = JSON.parse(JSON.stringify(item))
        get("item_likes", item.url)
          .then(res => {
            if (res && res.target.result) {
              //console.log("----- found " + JSON.stringify(res.target.result))
              let updatedItem = res.target.result
              if (amount) updatedItem.amount += amount

              item.added_date = updatedItem.item.added_date
              item.amount = updatedItem.amount
              let r = putKV("item_likes", item.url, {
                item: item,
                alias: item.creator?.id,
                domain: item.publisher.id,
                amount: updatedItem.amount,
                tags: item.tags,
                ts: updatedItem.ts,
                search: preapareSearch(item),
              })

              if (r) {
                r.onsuccess = function (e) {
                  console.log("Added")
                }
                r.onerror = function (e) {
                  console.log("error")
                }
              }
            } else {
              //console.log("----- not found ")
              item.added_date = Math.floor(Date.now() / 1000)
              let r = putKV("item_likes", item.url, {
                item: item,
                alias: item.creator?.id,
                domain: item.publisher.id,
                amount: amount,
                tags: item.tags,
                ts: Date.now(),
                search: preapareSearch(item),
              })
              if (r) {
                r.onsuccess = function (e) {
                  console.log("Added")
                }
                r.onerror = function (e) {
                  console.log("error")
                }
              }
            }
          })
          .catch(error => {
            console.log("failed to read like ", error)
          })
      },
      [addKV]
    )

    const updateChannelLikes = useCallback(
      (dbName: string, item: Channel, amount: number) => {
        //console.log("storing channel like")

        setChannelLikes(channelLikes =>
          channelLikes.includes(item.id)
            ? channelLikes
            : [...channelLikes, item.id]
        )

        get(dbName, item.id)
          .then(res => {
            if (res && res.target.result) {
              //console.log("----- found " + JSON.stringify(res.target.result))
              let updatedItem = res.target.result
              updatedItem.amount += amount
              updatedItem.ts = Date.now()

              let r = putKV(dbName, item.id, updatedItem)
              if (r) {
                r.onsuccess = function (e) {
                  console.log("Added")
                }
                r.onerror = function (e) {
                  console.log("error")
                }
              }
            } else {
              //console.log("----- not found ")
              let r = putKV(dbName, item.id, {
                item: item,
                alias: item.id,
                domain: item.id,
                amount: amount,
                tags: item.tags,
                ts: Date.now(),
              })
              if (r) {
                r.onsuccess = function (e) {
                  console.log("Added")
                }
                r.onerror = function (e) {
                  console.log("error")
                }
              }
            }
          })
          .catch(error => {
            console.log("failed to read like ", error)
          })
      },
      [putKV, get]
    )
    /*
    useEffect(() => {
      console.log("trying updating subscriptions")
      if (!user) return
      let now = Math.floor(new Date().getTime() / 1000)
      if (now - lastSubscriptionQuery < 10) return
      lastSubscriptionQuery = now
      console.log("updating subscriptions")
      let channel_ids = []
      for (var i = 0; i < user.subscriptions.length; i++)
        channel_ids.push(user.subscriptions[i].id)
      GET_subscriptions(
        channel_ids.join(","),
        user?.displayed_currency,
        user?.id
      ).then(res => {
        if (res) {
          let subscriptions = user?.subscriptions
          for (var ch in res.channels) {
            for (var i = 0; i < subscriptions.length; i++) {
              if (ch === subscriptions[i].id) {
                subscriptions[i].recent_content = res[ch]
                break
              }
            }
          }
          setCurrencyConverterRate(res.rate)
          setUser(
            user =>
              user && {
                ...user,
                subscriptions: subscriptions,
                displayed_currency_rate: res.rate,
                post: res.post,
              }
          )
        }
      })
    }, [user?.subscriptions, user?.displayed_currency])
    */

    const updateSubscriptions = useCallback(
      subscriptions => {
        //console.log("trying updating subscriptions")
        if (!user) return
        let now = Math.floor(new Date().getTime() / 1000)
        if (now - lastSubscriptionQuery < 10) return
        lastSubscriptionQuery = now
        let channel_ids = []
        for (var i = 0; i < user.subscriptions.length; i++)
          channel_ids.push(user.subscriptions[i].id)
        GET_subscriptions(
          channel_ids.join(","),
          user?.displayed_currency,
          user?.id
        ).then(res => {
          if (res) {
            setCurrencyConverterRate(res.rate)
            try {
              var channels = JSON.parse(res.channels)
              for (var ch in channels) {
                for (var i = 0; i < subscriptions.length; i++) {
                  if (ch === subscriptions[i].id) {
                    subscriptions[i].recent_content = channels[ch]
                    break
                  }
                }
              }
            } catch (e) {
              console.log("failed to update subscriptions " + res.channels)
            }
            setUser(
              user =>
                user && {
                  ...user,
                  subscriptions: subscriptions,
                  displayed_currency_rate: res.rate,
                  post: res.post,
                }
            )
          }
        })
      },
      [user?.subscriptions]
    )
    /*
    useEffect(() => {
      console.log(
        "setCurrencyConverterRate =============== " +
          user.displayed_currency_rate
      )
      if (user && user.displayed_currency_rate)
        setCurrencyConverterRate(user.displayed_currency_rate)
    }, [user?.displayed_currency_rate])
*/
    const updateChannelViews = useCallback(
      (ch: string) => {
        if (user) {
          for (var i = 0; i < user.subscriptions.length; i++) {
            if (ch === user.subscriptions[i].id) {
              let newSubscriptions = user.subscriptions
              newSubscriptions[i].last_viewed = Math.floor(
                new Date().getTime() / 1000
              )
              newSubscriptions[i].num_views = newSubscriptions[i].num_views
                ? newSubscriptions[i].num_views + 1
                : 1

              newSubscriptions.sort((a, b) =>
                a.num_views > b.num_views
                  ? -1
                  : b.num_views > a.num_views
                  ? 1
                  : 0
              )

              setUser(
                user =>
                  user && {
                    ...user,
                    subscriptions: newSubscriptions,
                  }
              )
              break
            }
          }
        }
      },
      [user?.subscriptions]
    )

    const addBookmark = useCallback(
      (b: Bookmark) => {
        if (user) {
          let newBookmarks = [b].concat(user.bookmarks)

          newBookmarks.sort((a, b) => {
            if (a.tags[0] < b.tags[0]) return -1
            else if (b.tags[0] < a.tags[0]) return 1
            else {
              if (a.tags.length > 1 && b.tags.length > 1) {
                if (a.tags[1] < b.tags[1]) return -1
                else if (b.tags[1] < a.tags[1]) return 1
              }
              return 0
            }
          })

          setUser(
            user =>
              user && {
                ...user,
                bookmarks: newBookmarks,
              }
          )
        }
      },
      [user, user?.bookmarks]
    )
    const selectBookmark = useCallback(
      (id: number) => {
        if (user) {
          for (var i = 0; i < user.bookmarks.length; i++) {
            if (user.bookmarks[i].id == id) {
              //updateFilters({ tags: user.bookmarks[i].tags })
              let newBookmarks = user.bookmarks
              newBookmarks[i].lastViewed = Math.floor(Date.now() / 1000)
              newBookmarks[i].numViews = newBookmarks[i].numViews + 1
              setUser(
                user =>
                  user && {
                    ...user,
                    bookmarks: newBookmarks,
                  }
              )
              break
            }
          }
        }
      },
      [user?.bookmarks]
    )
    const removeBookmark = useCallback(
      (id: number) => {
        if (user) {
          for (var i = 0; i < user.bookmarks.length; i++) {
            if (user.bookmarks[i].id == id) {
              user.bookmarks.splice(i, 1)
              setUser(
                user =>
                  user && {
                    ...user,
                    bookmarks: user.bookmarks,
                  }
              )
              break
            }
          }
        }
      },
      [user?.bookmarks]
    )

    const loadItemLikes = useCallback(
      async (items: Item[], skipLoad: boolean) => {
        if (skipLoad == false) {
          for (var i = 0; i < items.length; i++) {
            if (itemLikes.includes(items[i].url) == false) {
              getKey("item_likes", items[i].url)
                .then(res => {
                  if (res && res.target.result) {
                    setItemLikes(likes => [...likes, res.target.result])
                  }
                })
                .catch(error => {
                  console.log("failed to read like ", error)
                })
            }
          }
        } else {
          let newItems = []
          for (var i = 0; i < items.length; i++) {
            if (itemLikes.includes(items[i].url) == false) {
              newItems.push(items[i].url)
            }
          }
          setItemLikes(itemLikes.concat(newItems))
        }
      },
      [getKey, itemLikes, setItemLikes]
    )

    const loadChannelLikes = useCallback(
      async (items: Channel[], skipLoad: boolean) => {
        if (skipLoad == false) {
          for (var i = 0; i < items.length; i++) {
            if (channelLikes.includes(items[i].id) == false) {
              getKey(
                items[i].kind == 1 ? "website_likes" : "author_likes",
                items[i].id
              )
                .then(res => {
                  if (res && res.target.result) {
                    //console.log("found like " + res.target.result)
                    setChannelLikes(likes => [...likes, res.target.result])
                  }
                })
                .catch(error => {
                  console.log("failed to read like ", error)
                })
            }
          }
        } else {
          let newItems = []
          for (var i = 0; i < items.length; i++) {
            if (channelLikes.includes(items[i].id) == false) {
              newItems.push(items[i].id)
            }
          }
          setChannelLikes(channelLikes.concat(newItems))
        }
      },
      [getKey, channelLikes, setChannelLikes]
    )

    /*
    const loadCommentatorBans = useCallback(
      async (items: Channel[], skipLoad: boolean) => {
        if (skipLoad == false) {
          for (var i = 0; i < items.length; i++) {
            if (channelLikes.includes(items[i].id) == false) {
              getKey(
                items[i].kind == 1 ? "website_likes" : "author_likes",
                items[i].id
              )
                .then(res => {
                  if (res && res.target.result) {
                    //console.log("found like " + res.target.result)
                    setChannelLikes(likes => [...likes, res.target.result])
                  }
                })
                .catch(error => {
                  console.log("failed to read like ", error)
                })
            }
          }
        } else {
          let newItems = []
          for (var i = 0; i < items.length; i++) {
            if (channelLikes.includes(items[i].id) == false) {
              newItems.push(items[i].id)
            }
          }
          setChannelLikes(channelLikes.concat(newItems))
        }
      },
      [getKey, channelLikes, setChannelLikes]
    )

*/

    const updateBalance = useCallback(
      (balance: number) => {
        console.log("updating balance " + balance)
        if (user) {
          let newBalance = user.balance
          newBalance.balance += balance
          setUser(
            user =>
              user && {
                ...user,
                balance: newBalance,
              }
          )
        }
      },
      [user]
    )

    const addRepeatingDonationById = useCallback(
      async (channelId: string, amount: number, period: number) => {
        const res = await GET_channel(channelId)
        if (res) {
          let ch = convertChannel(res)
          //console.log(ch)
          if (ch) {
            addRepeatingDonation(ch, amount, period)
          } else {
            //alert("failed to convert " + res)
          }
        } else {
          //alert("failed to fetch " + channelId)
        }
      },
      [user]
    )

    const addRepeatingDonation = useCallback(
      (channel: Channel, amount: number, period: number) => {
        const now = Math.floor(new Date().getTime() / 1000)
        let regularSupport = {
          amount: amount,
          period: period,
          added: now,
          lastTransfer: now,
        }
        subscribe(channel, regularSupport)
      },
      [user]
    )

    const deleteRepeatingDonation = useCallback(
      async (channel: Channel, showConfirmation: boolean) => {
        if (user && channel.alias) {
          rpc(
            "delete_recurring_donation",
            channel.alias,
            user,
            getCachedSecret(),
            function (res) {
              let message
              if (res.status == "ok") {
                message = "Recurring donation removed"

                setUser(
                  user =>
                    user && {
                      ...user,
                      subscriptions: user.subscriptions.map(c => {
                        if (c.id !== channel.id) {
                          return c
                        } else {
                          c.regularSupport = null
                          return c
                        }
                      }),
                    }
                )
              } else message = "Failed to delete recurring donation"

              if (showConfirmation) {
                showToast(faInfoCircle, "Smartlike network", message)
              }
            }
          )
        }
      },
      [user]
    )

    const setBalance = useCallback(
      (balance: number) => {
        console.log("updating balance " + balance)
        let newBalance = user.balance
        newBalance.balance = balance
        if (user) {
          setUser(
            user =>
              user && {
                ...user,
                balance: newBalance,
              }
          )
        }
      },
      [user]
    )

    const syncProfile = useCallback(() => {
      if (user && !profileSynced) {
        console.log("syncing profile")
        rpc("get_profile", user?.id, user, getCachedSecret(), response => {
          console.log(response)
          if (response.status == "ok") {
            //response.data.aliases = JSON.parse(response.data.aliases)
            //console.log(JSON.stringify(response))
            setProfileSynced(true)
            if (user) {
              setUser(
                user =>
                  user && {
                    ...user,
                    profile: response.data,
                  }
              )
            }
          }
          //setLoading(false)
        })
      }
    }, [user, profileSynced])

    const updateCommentLikes = useCallback((commentId: string) => {
      setCommentLikes(commentLikes =>
        commentLikes.includes(commentId)
          ? commentLikes
          : [...commentLikes, commentId]
      )
    }, [])

    const getUserChannel = useCallback(() => {
      return user.profile
    }, [user])

    /**
     * Autologin via browser extension
     */
    /*
    useEffect(() => {
      if (!user) {
        SEND_getAccount()?.then(login).catch(console.warn)
      }
    }, [])
*/
    const subscribe = useCallback(
      (channel: Channel, regularSupport) => {
        console.log(channel)
        if (user && channel != undefined) {
          if (channel) {
            let i = 0
            for (; i < user.subscriptions.length; i++) {
              if (channel.id == user?.subscriptions[i].id) break
            }

            if (i == user.subscriptions.length) {
              channel.notifications = false
              channel = {
                ...channel,
                last_viewed: Math.floor(new Date().getTime() / 1000),
                num_views: 1,
                recent_content: 0,
                regularSupport: regularSupport,
              }
              let newSubscriptions = [channel].concat(user.subscriptions)
              updateSubscriptions(newSubscriptions)
            } else {
              setUser(
                user =>
                  user && {
                    ...user,
                    subscriptions: user.subscriptions.map(c => {
                      console.log(c.id + " " + channel.id)
                      if (c.id !== channel.id) {
                        return c
                      } else {
                        console.log("setting regular support for " + c.id)
                        c.regularSupport = regularSupport
                        return c
                      }
                    }),
                  }
              )
            }
          }
        }
      },
      [user?.id, user?.subscriptions]
    )

    const unsubscribe = useCallback(
      async (channel: Channel) => {
        deleteRepeatingDonation(channel, false)
        let channelId = channel.id
        if (user) {
          if (channelId.length) {
            updateSubscriptions(
              user.subscriptions.filter(
                subscription => subscription.id !== channelId
              )
            )
          }
        }
      },
      [user?.id]
    )
    /*
    const toggleNotifications = useCallback(
      async (channel: Channel) => {
        let i = 0
        for (; i < user.subscriptions.length; i++) {
          if (channel.id == user?.subscriptions[i].id) break
        }
        if (i == user.subscriptions.length) {
          subscribe(channel)
        }
        let objIndex = user.subscriptions.findIndex(obj => obj.id == channel.id)
        if (objIndex != -1) {
          let subscriptions = user.subscriptions
          subscriptions[objIndex].notifications = !subscriptions[objIndex]
            .notifications
          setUser(
            user =>
              user && {
                ...user,
                subscriptions: subscriptions,
              }
          )
        } else {
          console.log("channel not found")
        }
      },
      [user]
    )
*/
    const isNotificationSet = useCallback(
      (channelId: string) =>
        user?.subscriptions.some(
          subscription =>
            subscription.id === channelId && subscription.notifications == true
        ),
      [user?.subscriptions]
    )

    const isSubscribed = useCallback(
      (channelId: string) =>
        user?.subscriptions.some(subscription => subscription.id === channelId),
      [user?.subscriptions]
    )

    const getRepeatingDonation = useCallback(
      (channelId: string) => {
        if (user) {
          for (var i = 0; i < user.subscriptions.length; i++) {
            if (
              user.subscriptions[i].id === channelId &&
              user.subscriptions[i].regularSupport
            )
              return user.subscriptions[i].regularSupport
          }
        }
        return null
      },
      [user?.subscriptions]
    )

    const clearLocalUserData = useCallback(() => {
      localStorage.clear()
      clearIndexedDb()
      location.reload()
    }, [user, clearIndexedDb])

    return {
      user,
      login,
      login1,
      logout,
      isLoggingIn,
      isLoggedIn,
      subscribe,
      unsubscribe,
      isSubscribed,
      itemLikes,
      updateItemLikes,
      setItemLikes,
      loadItemLikes,
      loadChannelLikes,
      setBalance,
      updateBalance,
      channelLikes,
      updateChannelLikes,
      commentLikes,
      updateCommentLikes,
      updateProfile,
      setType,
      isNotificationSet,
      accountState,
      setAccountState,
      updateChannelViews,
      scheduleBalanceQuery,
      addBookmark,
      selectBookmark,
      removeBookmark,
      clearLocalUserData,
      showBookmarks,
      setShowBookmarks,
      showMoreSubscriptions,
      setShowMoreSubscriptions,
      getUserChannel,
      addRepeatingDonation,
      addRepeatingDonationById,
      deleteRepeatingDonation,
      getRepeatingDonation,
      setUser,
      updateSubscriptions,
      profileSynced,
      syncProfile,
    }
  }
)

export type UserPageContext = { user: User | null }

let cachedUser: User | null = null

export const getUser = async () => {
  //console.log("getUser")
  const { user: username } = cookie.loadAll()

  if (username) {
    if (!cachedUser || cachedUser.profile.name !== username) {
      cachedUser = await auth.login(username)
    }
  } else {
    cachedUser = null
  }

  return cachedUser
}

let cachedSecret: string | null = null
export const getCachedSecret = () => {
  return cachedSecret
}
