import { useMemo, DependencyList } from "react"
import { useTheme, Theme } from "./use-theme"

export const useStyle = <T extends { [key: string]: string | number }>(
    getStyle: (theme: Theme) => T,
    dependencies: DependencyList = []
) => {
    const { theme } = useTheme()
    const css = useMemo(() => getStyle(theme), [theme, ...dependencies])

    return css
}
