import { useState } from "react"
import { createSingletonHook } from "./create-singleton-hook"

export enum SideMode {
  Content = 0,
  Comment = 1,
  Donation = 2,
}

export const [useSideMode, SideModeProvider] = createSingletonHook(
  (sm: SideMode) => {
    const [sideMode, setSideMode] = useState(sm)

    return {
      sideMode,
      setSideMode,
    }
  }
)
