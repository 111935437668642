//import { NextPageContext } from "next"
//import { setCookie, destroyCookie } from "nookies"
import cookie from "react-cookies"
import { GET_user } from "./http"
import { User } from "../types"

export const login = async (id: string): Promise<User | null> => {
    const user = await GET_user(id)

    if (user) {
        cookie.save("user", user.profile.name, {
            maxAge: 30 * 24 * 60 * 60,
            path: "/",
        })
        return user
    } else {
        logout()
        return null
    }
}

export const logout = (): void => {
    cookie.remove("user")
}
