import { dom as faDOM } from "@fortawesome/fontawesome-svg-core"
import {
  createHistory,
  createMemorySource,
  LocationProvider,
  useLocation,
} from "@reach/router"
import React, { FunctionComponent, useState } from "react"
import { Helmet as Head } from "react-helmet"
//import "focus-visible" // :focus-visible polyfill
//import "react-widgets/dist/css/react-widgets.css"
//import "animate.css"
import Frame from "../components/layout/frame"
import Modal from "../components/layout/modal"
import {
  FiltersProvider,
  getFilters,
  getCommentFilters,
} from "../hooks/use-filters"
import { IndexedDBProvider } from "../hooks/use-indexeddb"
import { ModalProvider } from "../hooks/use-modal"
import { useStyle } from "../hooks/use-style"
import { getTheme, ThemeProvider } from "../hooks/use-theme"
import { UserProvider, useUser } from "../hooks/use-user"
import { darkTheme, lightTheme } from "../services/theme"
import { User } from "../types"
import { UpdateProvider } from "./use-update"
import { SideMode, SideModeProvider } from "./use-side-mode"
import { ToastContainer } from "react-toastify"
import ButterToast from "butter-toast"
import { MediaProvider, useMedia } from "./use-media"

//import { DBConfig } from "./db-config"
//import { initDB } from "react-indexed-db"

export const myContext = React.createContext(undefined)

//if (typeof window !== "undefined") initDB(DBConfig)

const RootLayout = props => {
  //const [items, setItems] = useState([])
  /*
      const [filters, setFilters] = useState<Filters>({
          sort: "amount",
          age: 0,
          nsfw: false,
          q: "",
          languages: [],
          countries: [],
      })*/

  //const onItemsFetch = useMemo(() => fetchItems(filters), [filters])
  //const onChannelsFetch = useMemo(() => fetchChannels(filters), [filters])

  //console.log("root")
  const [user, setUser] = useState<User | null>(null)
  const [indexedDB] = useState(null)
  const [theme, setTheme] = useState(getTheme())
  const [filters] = useState(getFilters())
  const [commentFilters] = useState(getCommentFilters())
  const source = createMemorySource("/starting/url")
  const history = createHistory(source)
  const [sideMode] = useState(SideMode.Content)

  //  useEffect(() => {
  //    console.log("***** useEffect()")
  /*
          fetch("/api/v1")
              .then(response => {
                  console.log(response)
                  return response.json()
              })
              .then(proposals => {
                  console.log(proposals)
              })*/

  //const user = Promise.resolve(getUser())
  //setUser(user)
  //setTheme(getTheme())

  //const appProps = await NextApp.getInitialProps(context)
  /*
          const user = getUser()
          const theme = getTheme()
          const filters = getFilters()
          const menu = getMenu()*/

  //return { ...appProps, user, theme, filters, menu }

  /*filters = {
              sort: "amount",
              age: 0,
              nsfw: false,
              q: "",
              languages: [],
              countries: [],
          }
          setFilters(filters)
          const p = fetchItems(filters)()*/
  //setItems1([])

  //if (filters == null) {
  //    setState({...state, items: items})

  //const filtersStorage = localStorage.getItem('filters')
  //if (filtersStorage != null) {
  //    setFilters(JSON.parse(filtersStorage))
  //    console.log('filtersStorage ' + filtersStorage)
  //  }, [])

  return (
    <>
      <Head>
        <title>Smartlike</title>
        <meta property="og:type" content="website" />
        <meta property="og:site_name" content="Smartlike" />
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="/favicons/smartlike-apple-touch-icon.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href="/favicons/smartlike-32.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href="/favicons/favicon-16x16.png"
        />
        <link rel="manifest" href="/manifest.webmanifest" />
        <link
          rel="mask-icon"
          href="/favicons/safari-pinned-tab.svg"
          color="#000000"
        />
        <meta name="msapplication-TileColor" content="#f5deb3" />
        <meta name="theme-color" content="#ffffff" />
        <link
          href="https://unpkg.com/nprogress@0.2.0/nprogress.css"
          rel="stylesheet"
        />
        <link
          href="https://cdnjs.cloudflare.com/ajax/libs/modern-normalize/0.5.0/modern-normalize.min.css"
          rel="stylesheet"
        />
        <link
          href="https://fonts.googleapis.com/css?family=Roboto:300,400&amp;display=swap&amp;subset=latin-ext"
          rel="stylesheet"
        />
        <style>{faDOM.css()}</style>
      </Head>
      <LocationProvider>
        <ThemeProvider value={theme}>
          <ModalProvider value={null}>
            <UpdateProvider>
              <IndexedDBProvider value={indexedDB}>
                <SideModeProvider value={sideMode}>
                  <UserProvider value={user}>
                    <FiltersProvider value={{ filters, commentFilters }}>
                      <MediaProvider>
                        <Page>{props.children}</Page>
                      </MediaProvider>
                    </FiltersProvider>
                  </UserProvider>
                </SideModeProvider>
              </IndexedDBProvider>
            </UpdateProvider>
          </ModalProvider>
        </ThemeProvider>
      </LocationProvider>
    </>
  )
}

const Page: FunctionComponent = ({ children }) => {
  const { isTouchDevice } = useMedia()
  const { pathname } = useLocation()
  const { numColumns } = useMedia()

  const css = useStyle(theme => ({
    backgroundColor:
      isTouchDevice && pathname == "/integration/"
        ? "wheat"
        : theme === "light"
        ? lightTheme.color.background.secondary
        : darkTheme.color.background.primary,
    textColor:
      theme === "light"
        ? lightTheme.color.text.primary
        : darkTheme.color.text.primary,
    scrollbarColor:
      theme === "light"
        ? lightTheme.color.scrollbar
        : darkTheme.color.scrollbar,
    borderColor:
      theme === "light" ? lightTheme.color.border : darkTheme.color.border,
    activeColor:
      theme === "light" ? lightTheme.color.active : darkTheme.color.active,
  }))

  const browser = typeof window !== "undefined" && window
  return (
    browser && (
      <>
        {pathname == "/integration/" ? (
          <>
            <div className="main">{children}</div>
            <ToastContainer />
            <ButterToast
              position={{
                horizontal: numColumns == 1 ? "POS_CENTER" : "POS_RIGHT",
                vertical: "POS_BOTTOM",
              }}
            />
          </>
        ) : (
          <>{children}</>
        )}
        <Modal />

        <style jsx global>{`
          /*
          #___gatsby {
            overflow-x: hidden;
            position: relative;
          }*/
          html {
            font-size: 15px;
            font-family: Roboto, sans-serif;
            overscroll-behavior-x: none;
          }
          body {
            color: #747474;
            font-family: Roboto, sans-serif;
            font-weight: lighter;
            background-color: ${css.backgroundColor};
            color: ${css.textColor};
            transition: 200ms color, 200ms background-color;
            overflow-y: scroll;
            overflow-x: hidden;
            overscroll-behavior-x: none;
          }
          h1,
          h2,
          h3,
          h4,
          h5,
          h6,
          p {
            line-height: 130%;
          }
          h1:first-child,
          h2:first-child,
          h3:first-child,
          h4:first-child,
          h5:first-child,
          h6:first-child,
          p:first-child {
            margin-top: 0;
          }
          h1:last-child,
          h2:last-child,
          h3:last-child,
          h4:last-child,
          h5:last-child,
          h6:last-child,
          p:last-child {
            margin-bottom: 0;
          }
          h1 {
            font-weight: 500;
          }

          a {
            color: inherit;
          }
          input {
            font: inherit;
          }
          button:hover {
            cursor: pointer;
          }
          button:disabled {
            cursor: not-allowed;
          }
          ::-webkit-input-placeholder {
            opacity: 1;
            color: inherit;
          }
          #nprogress .bar {
            background: ${css.activeColor};
            height: 1px;
          }
          #nprogress .spinner-icon {
            border-top-color: ${css.activeColor};
            border-left-color: ${css.activeColor};
          }
          #nprogress .peg {
            box-shadow: 0 0 10px ${css.activeColor}, 0 0 5px ${css.activeColor};
          }
          /**
                  Scrollbars
                   */
          * {
            transition: 200ms scrollbar-color;
            scrollbar-width: thin;
            scrollbar-color: ${css.scrollbarColor} ${css.borderColor};
          }
          *::-webkit-scrollbar {
            width: 8px;
          }
          *::-webkit-scrollbar-thumb {
            background-color: ${css.scrollbarColor};
            transition: 200ms background-color;
          }
          *::-webkit-scrollbar-track {
            background-color: ${css.borderColor};
            transition: 200ms background-color;
          }
          /*
                  This will hide the focus indicator if the element receives focus via the mouse,
                  but it will still show up on keyboard focus.
                  */
          .focus-visible {
            outline-color: ${css.activeColor};
          }
          :focus:not(.focus-visible) {
            outline: none;
          }
          /*
        .MuiTooltip-tooltip {
          background-color: #ffe180 !important;
          color: #000000 !important;
          font-size: 14px !important;
          padding: 10px !important;
        }
*/
          .react-switch-handle {
            height: 14px !important;
            width: 14px !important;
          }
          .react-switch-bg {
            height: 16px !important;
            width: 46px !important;
          }

          div.ReactTags__tags {
            position: relative;
          }

          /* Styles for the input */
          div.ReactTags__tagInput {
            margin: 5px;
            width: 140px;
            border-radius: 2px;
            display: inline-block;
          }
          div.ReactTags__tagInput input.ReactTags__tagInputField,
          div.ReactTags__tagInput input.ReactTags__tagInputField:focus {
            height: 31px;
            margin: 0;
            font-size: 12px;
            width: 100%;
            border: 1px solid #eee;
          }

          /* Styles for selected tags */
          div.ReactTags__selected span.ReactTags__tag {
            border: 1px solid #ddd;
            background: #eee;
            font-size: 12px;
            display: inline-block;
            padding: 5px;
            margin: 0 5px;
            border-radius: 2px;
          }
          div.ReactTags__selected a.ReactTags__remove {
            color: #aaa;
            margin-left: 5px;
            cursor: pointer;
          }

          /* Styles for suggestions */
          div.ReactTags__suggestions {
            position: absolute;
            z-index: 100;
            /*font-family: "Roboto";*/
            font-size: 12px;
          }
          div.ReactTags__suggestions ul {
            list-style-type: none;
            box-shadow: 0.05em 0.01em 0.5em rgba(0, 0, 0, 0.2);
            background: white;
            /*width: 300px;*/
            padding-inline-start: 0px;
            columns: 3;
            -webkit-columns: 3;
            -moz-columns: 3;
            padding: 5px;
            white-space: nowrap;
          }
          div.ReactTags__suggestions li {
            /*border-bottom: 1px solid #ddd;*/
            padding: 0px 10px;
            margin: 0;
            line-height: 20px;
          }
          div.ReactTags__suggestions li mark {
            text-decoration: underline;
            background: none;
            font-weight: 600;
          }
          div.ReactTags__suggestions ul li.ReactTags__activeSuggestion {
            background: #b7cfe0;
            cursor: pointer;
          }

          @keyframes onautofillstart {
            from {
            }
          }
          @keyframes onautofillcancel {
            from {
            }
          }

          input:-internal-autofill-selected {
            // Expose a hook for JavaScript when autofill is shown
            // JavaScript can capture 'animationstart' events
            animation-name: onautofillstart;

            // Make the background color become yellow really slowly
            transition: background-color 50000s ease-in-out 0s;
          }
          input:not(:-webkit-autofill) {
            // Expose a hook for JS onAutoFillCancel
            // JavaScript can capture 'animationstart' events
            animation-name: onautofillcancel;
          }
        `}</style>
      </>
    )
  )
}

//render(<App />, document.getElementById("root"))
//export default RootLayout
export default ({ element }) => <RootLayout>{element}</RootLayout>
