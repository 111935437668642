import qs from "querystring"
import { useCallback, useEffect, useState } from "react"
import { createSingletonHook } from "./create-singleton-hook"
import { useMounted } from "./use-mounted"

export type Filters = {
  offset?: number
  sort?: string
  q?: string
  age?: number
  nc?: boolean
  languages?: string[]
  countries?: string[]
  channel_ids: string[]
  tags?: string[]
  donation_processor: string
  donation_country: string
  donation_currency: string
  donation_feed?: string
  donation_q: string
  liked: boolean
  moderated: boolean
  mod: number
  bitmask: number
}

export type CommentFilters = {
  sort?: string
  threshold?: number
  unread?: boolean
  owned?: boolean
}

export type Menu = "sort" | "age" | null

export const filterableRoutes = [
  "/",
  "/subscriptions",
  "/channel/",
  "/settings",
]

export const [useFilters, FiltersProvider] = createSingletonHook(
  (props: { filters: Filters; commentFilters: CommentFilters }) => {
    const [filters, setFilters] = useState(props.filters)
    const [commentFilters, setCommentFilters] = useState(props.commentFilters)

    const updateFilters = useCallback(
      (updatedFilters: Partial<Filters>) => {
        /*if (updatedFilters.sort === "date") {
          //updatedFilters.age = undefined
          if (menu === "age") {
            setMenu("sort")
          }
        } else if (filters.sort === "date" && updatedFilters.age) {
          updatedFilters.sort = "amount"
        }*/
        /*
        if (
          filters.sort === "date" &&
          updatedFilters.age &&
          updatedFilters.age != 0
        ) {
          updatedFilters.sort = "amount"
        }

        console.log(
          "++++++++++++++++++++++++++++++++++++++++++++++ updateFilters " +
            updatedFilters.channel_ids?.length +
            " " +
            filters.channel_ids.length
        )*/
        //console.log("updateFilters")
        setFilters({
          ...filters,
          ...updatedFilters,
        })
      },
      [filters]
    )

    const updateCommentFilters = useCallback(
      (updatedFilters: Partial<CommentFilters>) => {
        setCommentFilters({
          ...commentFilters,
          ...updatedFilters,
        })
      },
      [commentFilters]
    )

    const isMounted = useMounted()

    useEffect(() => {
      saveFilters(filters)
    }, [filters])

    // TODO: fix rules regarding global search bar triggering feed reload

    /*
    useEffect(() => {
      if (isMounted) {
        const { pathname, query } = url.parse(location.pathname, true)
        let params = qs.stringify(updateQuery(filters, query))
        if (params) {
          params = "?" + params
        }

        console.log("location.pathname - " + location.pathname)
        if (filterableRoutes.includes(location.pathname) && pathname) {
          //useNavigate.push(location.pathname, pathname + params)
          navigate(location.pathname)
        } else {
          //history.push("/", "/" + params)
          navigate("/")
        }
      }
    }, [filters.sort, filters.q, filters.age, filters.channel_ids])
*/
    return {
      filters,
      updateFilters,
      setFilters,
      commentFilters,
      setCommentFilters,
      updateCommentFilters,
    }
  }
)

export const updateQuery = (
  filters: Filters,
  query: qs.ParsedUrlQuery
): qs.ParsedUrlQuery => {
  const params = {
    ...query,
    sort: filters.sort ?? "",
    h: filters.age?.toString() ?? "",
    q: filters.q ?? "",
  }

  if (params.sort !== "date" && params.sort !== "score") {
    delete params.sort
  }

  if (!params.h) {
    delete params.h
  }

  if (!params.q) {
    delete params.q
  }

  return params
}

const saveFilters = (filters: Filters) => {
  localStorage.setItem("filters", JSON.stringify(filters))
}

const first = <T>(x: T[] | T): T => (Array.isArray(x) ? x[0] : x)

export const getFilters = (): Filters => {
  if (typeof window !== "undefined") {
    let f = localStorage.getItem("filters")
    if (f) {
      try {
        let filters = JSON.parse(f)
        if (filters.mod == undefined) filters.mod = 0
        return filters
      } catch (e) {
        console.log("failed to parse filters")
        //setUser(null)
      }
    }
  }
  const filters: Filters = {
    sort: "date",
    age: 24,
    q: undefined,
    languages: [],
    countries: [],
    tags: [],
    channel_ids: [],
    donation_processor: "any",
    donation_currency: "any",
    donation_country: "any",
    donation_feed: "New",
    donation_q: "",
    liked: false,
    moderated: true,
    bitmask: 27363,
    nc: false,
    mod: 0,
  }

  if (typeof window !== "undefined") {
    const lcCode = navigator.language || navigator.userLanguage
    if (lcCode && lcCode.length) {
      const language = lcCode.substr(0, 2)
      if (language != "en") filters.languages?.push("en")
      filters.languages?.push(language)
    }
  }

  return filters

  /*
  //const { query } = context
  const cookies = cookie.loadAll()

  const location = useLocation()
  //console.log("******************** 11 ")
  const search = location.search ? queryString.parse(location.search) : {}
  //console.log(search)

  const filters: Filters = {
    sort: "amount",
    age: 0,
    nsfw: false,
    q: undefined,
    languages: [],
    countries: [],
    tags: [],
  }


  // Public filter params

  if ("sort" in search && search.sort === "date") {
    filters.sort = "date"
  } else if ("h" in search) {
    filters.age = Number(search.h)
  }

  if ("q" in search) {
    filters.q = search.q
  }

  // Hidden filter params

  const nsfw = parseInt(cookies.filter_nsfw)
  if (nsfw === 1) {
    filters.nsfw = true
  }
  const languageCodes = Object.keys(LANGUAGES)
  const languages = cookies.filter_languages
    ?.split(",")
    .filter(languageCode => languageCodes.includes(languageCode))
  if (languages?.length) {
    filters.languages = languages
  }

  const countryCodes = Object.keys(COUNTRIES)
  const countries = cookies.filter_countries
    ?.split(",")
    .filter(countryCode => countryCodes.includes(countryCode))
  if (countries?.length) {
    filters.countries = countries
  }
*/
}

export const getCommentFilters = (): CommentFilters => {
  if (typeof window !== "undefined") {
    let f = localStorage.getItem("comment_filters")
    if (f) {
      try {
        let filters = JSON.parse(f)
        if (filters.mod == undefined) filters.mod = 0
        return filters
      } catch (e) {
        console.log("failed to parse filters")
      }
    }
  }
  const commentFilters: CommentFilters = {
    sort: "date",
    threshold: 0,
    unread: false,
    owned: false,
  }
  return commentFilters
}
